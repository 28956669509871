import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_END_POINTS } from 'utils/constants';
import httpClient from 'utils/http-client';

export const regionList = createAsyncThunk(API_END_POINTS.REGIONS, async (payload, thunkAPI) => {
  try {
    const res = await httpClient.get(API_END_POINTS.REGIONS, payload);
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState = {
    data: [],
    isLoading: false,
    errorMessage:null,
}

export const regionSlice = createSlice({
  name: 'regions',
  initialState: initialState,
  // non-async reducers
  reducers: {},
  // async reducers
  extraReducers: builder => {
    builder.addCase(regionList.pending, (state, action) => {
        state.isLoading = true;
    })
    builder.addCase(regionList.fulfilled, (state, action) => {
      const { region } = action.payload || {};
        state.isLoading = false;
        state.data = region
    })
      builder.addCase(regionList.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.payload.response ? action.payload.response.data.data ? action.payload.response.data.data : action.payload.response.data : ''
    })
  }
});

export default regionSlice.reducer;



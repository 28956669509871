export const ROUTES = {
  DEFAULT: "/",
  ABOUT_US: "about-us",
  HELP_CENTER: "help-center",
  COOKIES_POLICY: "cookies-policy",
  PRIVACY_POLICY: "privacy-policy",
  TERMS_AND_CONDITIONS: "terms-and-conditions",
  LOGIN: "login",
  FORGOTPASSWORD: "forgot-password",
  RESETPASSWORD: "reset-password",
  RESETPASSWORD_SUCCESS: "reset-password/success",
  SIGNUP: "signup",
  PROJECT: "project",
  PROJECT_DETAILS: "/project/:id",
  ACTIVITIES: "activities",
  LIMITS: "limits",
  SUPPORT: "support",
  DASHBOARD: "dashboard",
  REQUISITION: "requisitions",
  SERVERS: "server",
  SERVER_CREATE: "server/create",
  SERVER_DETAIL: "/project/:id/server/:serverId/",
  SERVER_OVERVIEW: "overview",
  SERVER_NETWORK: "network",
  SERVER_VOLUMES: "volumes",
  SERVER_BACKUPS: "backups",
  SERVER_FIREWALL: "firewall",
  SERVER_STATISTICS: "statistics",
  SERVER_EVENTS: "history",
  SERVER_SNAPSHOT: "snapshot",
  SERVER_ACCESS: "access",
  SERVER_DESTROY: "destroy",
  SERVER_RESIZE: "resize",
  SERVER_CONSOLE: "console",
  VOLUMES: "volumes",
  NETWORKS: "networks",
  IMAGES: "images",
  FLOATING_IP: "floating-ip",
  DNS: "dns",
  USERS: "users",
  EVENTS: "events",
  DELETE: "delete",
  ACCOUNT_SETTINGS: "/settings/account",
  ACCOUNT_USAGE: "/settings/usage",
  ACCOUNT_PAYMENT: "/settings/payment",
  ACCOUNT_PAYMENT_GATEWAY: "/settings/payment-gateway",
  ACCOUNT_USAGE_HOURLY: "/settings/usage/:value",
  ACCOUNT_SSHKEY: "/settings/sshkey",
  PAYMENT_SUCCESS: "payment/success",
};

export const SERVER_DETAIL_TABS = [
  "overview",
  "access",
  "network",
  "volumes",
  "backups",
  "firewall",
  "statistics",
  "history",
  "resize",
  "destroy",
];

export const IDLE_TIME_OUT = 43200000;

// export const API_BASE_URL = "https://devapi.cafric.net/api/v1/";
// export const BASE_URL = "https://devvc.cafric.net/";

export const API_BASE_URL = "https://vapi.vodacom.co.tz/api/v1/";
export const BASE_URL = "https://vcmanager.vodacom.co.tz/";

// export const API_BASE_URL = process.env.REACT_APP_CXC_API_BASE_URL;
// export const BASE_URL = "https://" + process.env.REACT_APP_CXF_BASE_URL;

export const API_END_POINTS = {
  PROJECTS: "project/",
  PROJECTS_SUMMARY: "project/summary/",
  PROJECTS_DELETE: "project/{project_id}/delete/",
  INSTANCE_CREATION: "instance/",
  INSTANCES: "instance/{project_id}/list/",
  INSTANCES_START: "instance/{instance_id}/start/",
  INSTANCES_STOP: "instance/{instance_id}/stop/",
  INSTANCES_OVERVIEW: "instance/{instance_id}/overview/",
  INSTANCES_VOLUME: "volume/instance/{instance_id}/",
  INSTANCES_NETWORKS: "network/instance/{instance_id}/",
  INSTANCE_DESTROY: "instance/{instance_id}/destroy/",
  INSTANCE_BACKUP_SCHEDULE: "instance/{instance_id}/backup/schedule/",
  INSTANCE_BACKUP: "instance/{instance_id}/backup/",
  INSTANCE_BACKUP_ACTION: "instance/backup/{backup_id}/",
  INSTANCE_RENAME: "instance/{instance_id}/rename/",
  INSTANCE_STATUS: "instance/{instance_id}/status/",
  INSTANCE_STATISTICS: "instance/{instance_id}/stats/",
  INSTANCE_BANDWIDTH: "instance/{instance_id}/bandwidth/",
  INSTANCE_GET_BANDWIDTH: "instance/{instance_id}/bandwidth/list",
  INSTANCE_GET_PUBLIC_IP: "instance/{instance_id}/pubip/",
  INSTANCE_FIREWALL: "instance/{instance_id}/firewall/",
  INSTANCE_FIREWALL_CREATE: "instance/{instance_id}/firewall/create",
  INSTANCE_FIREWALL_DELETE: "instance/{instance_id}/firewall/{rule_id}/",
  INSTANCE_RESET_ROOT_PASSWORD: "instance/{instance_id}/password/",
  INSTANCE_RESET_SSH_KEY: "instance/{instance_id}/sshkey/",
  INSTANCE_REBOOT: "instance/{instance_id}/reboot/",
  INSTANCE_CONSOLE: "instance/{instance_id}/console/",
  INSTANCE_UPGRADE_PLANS: "instance/{instance_id}/upgrade/plan/",
  INSTANCE_RESIZE: "instance/{instance_id}/resize/",
  INSTANCE_DEFAULT_PRIVATE_NETWORK: "instance/{instance_id}/default-network/",
  VOLUMES: "volume/",
  PROJECTS_VOLUME: "volume/project/{project_id}/",
  VOLUME_OVERVIEW: "volume/{volume_id}/overview",
  VOLUMES_STATUS: "volume/{volume_id}/status",
  VOLUMES_ATTACH: "volume/{volume_id}/attach/{instance_id}/",
  VOLUMES_DETACH: "volume/{volume_id}/detach",
  VOLUMES_DELETE: "volume/{volume_id}/",
  FLOATING_IP: "floatingip/{project_id}/",
  FLOATING_IP_OVERVIEW: "floatingip/{floatingip_id}/overview/",
  FLOATING_IP_CREATION: "floatingip/{project_id}/create",
  FLOATING_IP_ATTACH: "floatingip/{floatingip_id}/attach/",
  FLOATING_IP_DETACH: "floatingip/{floatingip_id}/detach/",
  FLOATING_IP_DELETE: "floatingip/{floatingip_id}/delete/",
  REGIONS: "region/",
  IMAGES: "images/",
  PRIVATE_IMAGES: "images/project/{project_id}/",
  PRIVATE_IMAGES_CREATE: "images/project/{project_id}/create",
  PRIVATE_IMAGES_DELETE: "images/project/{project_id}/delete/{image_id}",
  PRIVATE_IMAGE_STATUS: "images/project/{project_id}/image/{image_id}",
  BILLING_DAILY: "billing/daily/",
  BILLING_HOURLY: "billing/hourly/",
  GET_TAX_DETAILS: "billing/tax/",
  EVENTS_INSTANCE: "events/instance/{instance_id}/",
  EVENTS_USERS: "events/user/",
  EVENTS_PROJECT: "events/projects/{project_id}/",
  PROJECT_USERS: "project/{project_id}/user/",
  PROJECT_INVITED_USERS: "project/{project_id}/invitation/",
  PROJECT_USER_DELETE: "project/{project_id}/user/{user_id}",
  PROJECT_INVITE_DELETE: "project/{project_id}/invitation/{invite_id}/",
  PROJECT_USER_INVITE: "project/{project_id}/user/invite",
  PROJECT_USER_PERMISSIONS: "project/{project_id}/permission/{user_id}/",
  PROJECT_USER_PERMISSION_EDIT:
    "project/{project_id}/permission/{user_id}/edit",
  PROJECT_ACCEPT_INVITATION: "project/{project_id}/invite/accept/",
  PROJECT_DENY_INVITATION: "project/{project_id}/invite/deny/",
  PRICE: "price/",
  LIMITS: "resourcelimit/",
  PLANS: "instance/plan/{region_id}/",
  SSHKEYS: "sshkey/",
  SSHKEY_CREATE: "sshkey/create/",
  SSHKEYS_PROJECT: "sshkey/project/{project_id}/",
  SSHKEYS_DELETE: "sshkey/{ssh_key_id}/",
  SIGNUP: "user/signup/",
  MFA: "user/mfa/",
  LOGIN: "user/login/",
  FORGOTPASSWORD: "user/forgot/password/",
  RESETPASSWORD: "user/reset/password/",
  LOGOUT: "user/logout/",
  USER_DETAILS: "user/detail/",
  EDIT_USER_DETAILS: "user/detail/edit",
  USER_INVITATIONS: "user/invitations/",
  CHANGE_PASSWORD: "user/changepassword/",
  COUNTRIES: "country/",
  STATE: "state/{country_id}/",
  BILLING_ADDRESS_DETAILS: "user/billing/address/",
  EDIT_BILLING_ADDRESS_DETAILS: "user/billing/address/edit",
  PAYEMNT_HISTORY: "user/payment/history/",
  PAYEMNT_GATEWAY_LIST: "pg/list/",
  WALLET_BALANCE: "user/wallet/balance/",
  ADD_DPO_PAYMENT: "pg/initiate/dpo/transaction/",
  ADD_MPESA_PAYMENT: "pg/initiate/mpesa/transaction/",
  VERIFY_PAYMENT: "pg/dpo/token/validate/",
  NEWS_LETTER_SUBSCRIBE: "newsletter/",
  NETWORKS: "network/{project_id}/",
  NETWORK_CREATION: "network/{project_id}/create",
  NETWORK_CIDR: "network/cidr/{project_id}/",
  NETWORK_STATUS: "network/{network_id}/status",
  NETWORK_DELETE: "network/{network_id}/delete",
  NETWORK_ATTACH: "network/{network_id}/attach/",
  NETWORK_DETACH: "network/{network_id}/detach/",
  NETWORK_IP_VALIDATE: "network/validate-ip/{network_id}/",
  REQUISITION_COUNT: "requisition/count/{project_id}",
  REQUISITION_LIST: "requisition/{project_id}/list/",
  REQUISITION_APPROVE: "requisition/{requisition_id}/approve/",
  REQUISITION_CANCEL: "requisition/{requisition_id}/cancel/",
};

// export const SYSTEM_TOKEN =
//   "ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmxibU52WkdWa0lqb2lRVkJKTERFMUxFOTZhWEJoVFZwNmNrdEJRa0ZCYmtSVFEyTnZaamQyYUZoUGExcENaV3AwTTA4aWZRLmkwcTRzd2F5MWp5b1VKOXZFTThCS2V6THFVc21xRTA4dFVEOFE1OXFtekE";

// export const SYSTEM_TOKEN = process.env.REACT_APP_CXC_API_TOKEN;

export const SYSTEM_TOKEN =
  "ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmxibU52WkdWa0lqb2lRVkJKTERRc2EyUkJUVU5wVGtSbmJFYzFhWEZJZVRGMU1sSjVaMFYzWnpabVJrSnNjbEF5ZGlKOS5tUVNjSVNkMi1nUllqbjVYa25uU0U0ZmVTdlpMcllyQWFWcnhBUEJUZWxZ";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const updateBandwidth = createAsyncThunk(
  API_END_POINTS.INSTANCE_BANDWIDTH,
  async (params, thunkAPI) => {
    try {
      const { id, payload } = params;
      const updateBandwidUrl = API_END_POINTS.INSTANCE_BANDWIDTH;
      const url = updateBandwidUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.post(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getBandwidth = createAsyncThunk(
  API_END_POINTS.INSTANCE_GET_BANDWIDTH,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const instanceBandwidthUrl = API_END_POINTS.INSTANCE_BANDWIDTH;
      const url = instanceBandwidthUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  isUpdated: false,
  isLoading: false,
  isUpdating: false,
  bandwidthValue: "",
  defaultBandwidth: "",
  publicIpDetails: {},
  updateBandwidthErrorMessage: null,
  getBandwidthErrorMessage: null,
  getPublicIpErrorMessage: null,
};

export const bandwidthSlice = createSlice({
  name: "bandwidth",
  initialState: initialState,
  // non-async reducers
  reducers: {
    resetBandwidth: (state) => {
      state.isUpdated = false;
      state.isLoading = false;
      state.isUpdating = false;
      state.bandwidthValue = "";
      state.updateBandwidthErrorMessage = null;
      state.getBandwidthErrorMessage = null;
    },
    resetBandwidthErrors: (state) => {
      state.updateBandwidthErrorMessage = null;
      state.getBandwidthErrorMessage = null;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(updateBandwidth.pending, (state, action) => {
      state.isUpdating = true;
    });
    builder.addCase(updateBandwidth.fulfilled, (state, action) => {
      state.isUpdated = true;
      state.isUpdating = false;
    });
    builder.addCase(updateBandwidth.rejected, (state, action) => {
      state.isUpdating = false;
      state.updateBandwidthErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(getBandwidth.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getBandwidth.fulfilled, (state, action) => {
      const { bandwidth, defaultBandwidth } = action.payload || {};
      state.bandwidthValue = bandwidth;
      state.defaultBandwidth = defaultBandwidth;
      state.isLoading = false;
    });
    builder.addCase(getBandwidth.rejected, (state, action) => {
      state.isLoading = false;
      state.getBandwidthErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
  },
});

export const { resetBandwidth, resetBandwidthErrors } = bandwidthSlice.actions;

export default bandwidthSlice.reducer;

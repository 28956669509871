import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_END_POINTS } from 'utils/constants';
import httpClient from 'utils/http-client';



export const getConsoleUrl = createAsyncThunk(
    API_END_POINTS.INSTANCE_CONSOLE, async (payload, thunkAPI) => {
    try {
        const { id } = payload
        const consoleUrl = API_END_POINTS.INSTANCE_CONSOLE
        const url = consoleUrl.replace("{instance_id}", id.toString())
        const res = await httpClient.get(url, payload);
        return res.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});


const initialState = {
    consoleUrl: "",
    isLoading: false,
    errorMessage:null,
    isSuccess: false,
}

export const consoleSlice = createSlice({
  name: 'consoleUrl',
  initialState: initialState,
  // non-async reducers
  reducers: {
    resetFirewall: (state) => {
        state.consoleUrl = [];
        state.isLoading = false;
        state.errorMessage = null;
        state.isSuccess = false;
    },
  },
  // async reducers
  extraReducers: builder => {
    builder.addCase(getConsoleUrl.pending, (state, action) => {
        state.isLoading = true;
    })
    builder.addCase(getConsoleUrl.fulfilled, (state, action) => {
      const { consoleUrl } = action.payload || {};
        state.isLoading = false;
        state.consoleUrl = consoleUrl
    })
      builder.addCase(getConsoleUrl.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.payload.response && action.payload.response.data.data ? action.payload.response.data.data : action.payload.response.data
    })
  }
});

export default consoleSlice.reducer;
import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoutes = ({ children }) => {
  const { userToken } = useSelector((state) => state.user);

  return (
    <React.Fragment>
      {userToken !== "" ? children : <Navigate to="/login" />}
    </React.Fragment>
  );
};

export default ProtectedRoutes;

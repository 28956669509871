import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const enableBackUp = createAsyncThunk(
  API_END_POINTS.INSTANCE_BACKUP_SCHEDULE,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const enableBackupUrl = API_END_POINTS.INSTANCE_BACKUP_SCHEDULE;
      const url = enableBackupUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.post(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const disableBackUp = createAsyncThunk(
  API_END_POINTS.INSTANCE_BACKUP_SCHEDULE + "disable",
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const disableBackupUrl = API_END_POINTS.INSTANCE_BACKUP_SCHEDULE;
      const url = disableBackupUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.delete(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createBackUp = createAsyncThunk(
  API_END_POINTS.INSTANCE_BACKUP,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const createBackupUrl = API_END_POINTS.INSTANCE_BACKUP;
      const url = createBackupUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.post(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const listBackUp = createAsyncThunk(
  API_END_POINTS.INSTANCE_BACKUP + "list",
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const listBackupUrl = API_END_POINTS.INSTANCE_BACKUP;
      const url = listBackupUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteBackUp = createAsyncThunk(
  API_END_POINTS.INSTANCE_BACKUP_ACTION + "/delete",
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const deleteBackupUrl = API_END_POINTS.INSTANCE_BACKUP_ACTION;
      const url = deleteBackupUrl.replace("{backup_id}", id.toString());
      const res = await httpClient.delete(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const restoreBackUp = createAsyncThunk(
  API_END_POINTS.INSTANCE_BACKUP_ACTION + "/restore",
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const restoreBackUpUrl = API_END_POINTS.INSTANCE_BACKUP_ACTION;
      const url = restoreBackUpUrl.replace("{backup_id}", id.toString());
      const res = await httpClient.put(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  backUpList: [],
  isLoading: false,
  isCreated: false,
  isDeleted: false,
  isRestored: false,
  isRestoring: false,
  isDeleting: false,
  backUpEnabled: false,
  backUpDisabled: false,
  statusMessage: "",
  backupId: "",
  backUpErrorMessage: null,
  backUpCreateErrorMessage: null,
  backUpRestoreErrorMessage: null,
  backUpDeleteErrorMessage: null,
};

export const backUpSlice = createSlice({
  name: "backUp",
  initialState: initialState,
  // non-async reducers
  reducers: {
    resetbackup: (state) => {
      state.backUpList = [];
    },
    resetbackupAction: (state) => {
      state.backUpDisabled = false;
      state.backUpEnabled = false;
      state.isCreated = false;
      state.isLoading = false;
      state.isDeleted = false;
      state.isRestored = false;
      state.isDeleting = false;
      state.isRestoring = false;
      state.backupId = 0;
      state.statusMessage = "";
      state.isListBackUpLoading = false;
    },
    updateBackupData: (state, param) => {
      const { payload } = param;
      const newState = state.backUpList.map((obj) => {
        if (obj.id === payload.backupId) {
          return {
            ...obj,
            status: payload.statusMessage,
          };
        }
        return obj;
      });
      state.backUpList = newState;
    },
    refreshBackupData: (state, param) => {
      const { payload } = param;
      state.backUpList = state.backUpList.filter(
        (item) => item.id !== payload.backupId
      );
    },
    resetBackupDataErrors: (state) => {
      state.backUpErrorMessage = null;
      state.backUpCreateErrorMessage = null;
      state.backUpRestoreErrorMessage = null;
      state.backUpDeleteErrorMessage = null;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(enableBackUp.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(enableBackUp.fulfilled, (state, action) => {
      state.backUpEnabled = true;
      state.backUpDisabled = false;
      state.isLoading = false;
    });
    builder.addCase(enableBackUp.rejected, (state, action) => {
      state.isLoading = false;
      state.backUpErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(disableBackUp.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(disableBackUp.fulfilled, (state, action) => {
      state.backUpDisabled = true;
      state.backUpEnabled = false;
      state.isLoading = false;
    });
    builder.addCase(disableBackUp.rejected, (state, action) => {
      state.isLoading = false;
      state.backUpErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(createBackUp.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createBackUp.fulfilled, (state, action) => {
      state.isCreated = true;
      state.isLoading = false;
    });
    builder.addCase(createBackUp.rejected, (state, action) => {
      state.isLoading = false;
      state.backUpCreateErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(listBackUp.pending, (state, action) => {
      state.isListBackUpLoading = true;
    });
    builder.addCase(listBackUp.fulfilled, (state, action) => {
      const { backups } = action.payload || {};
      state.backUpList = backups;
      state.isListBackUpLoading = false;
    });
    builder.addCase(listBackUp.rejected, (state, action) => {
      state.isListBackUpLoading = false;
      state.backUpCreateErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(deleteBackUp.pending, (state, action) => {
      state.isDeleting = true;
      state.isDeleted = false;
      state.statusMessage = "Deleting";
      state.backupId = action.meta.arg.id;
    });
    builder.addCase(deleteBackUp.fulfilled, (state, action) => {
      state.isDeleted = true;
      state.isDeleting = false;
      state.statusMessage = "Deleted";
      state.backupId = action.meta.arg.id;
    });
    builder.addCase(deleteBackUp.rejected, (state, action) => {
      state.isDeleting = false;
      state.isDeleted = false;
      state.statusMessage = "Completed";
      state.backupId = action.meta.arg.id;
      state.backUpDeleteErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(restoreBackUp.pending, (state, action) => {
      state.isRestoring = true;
      state.isRestored = false;
      state.statusMessage = "Restoring";
      state.backupId = action.meta.arg.id;
    });
    builder.addCase(restoreBackUp.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.isRestored = true;
        state.isRestoring = false;
        state.statusMessage = "Restoring";
        state.backupId = action.meta.arg.id;
      }
    });
    builder.addCase(restoreBackUp.rejected, (state, action) => {
      state.isRestoring = false;
      state.isRestored = false;
      state.statusMessage = "Restoring";
      state.backupId = action.meta.arg.id;
      state.backUpRestoreErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
  },
});

export const {
  resetbackup,
  resetbackupAction,
  updateBackupData,
  refreshBackupData,
  resetBackupDataErrors,
} = backUpSlice.actions;

export default backUpSlice.reducer;

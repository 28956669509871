import React, { useEffect } from "react";
import { element } from "prop-types";
import { AppHeader } from "components/app-header";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "slices/user";
import { IDLE_TIME_OUT, ROUTES } from "utils/constants";
import { useNavigate } from "react-router-dom";
import { getWalletBalance } from "slices/payment";
import IdleTimeOutHandler from "components/app-idle-timeout/IdleTimeOutHandler";

const AppLayout = ({ children }) => {
  const { userToken, firstName, lastName, baseCurrency } = useSelector(
    (state) => state.user
  );
  const { currentBalance } = useSelector((state) => state.payment);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isActive, setIsActive] = React.useState(true);

  const userLogout = () => {
    dispatch(logout({ mode: "manual" }));
  };

  useEffect(() => {
    dispatch(getWalletBalance());
  }, []);

  useEffect(() => {
    if (userToken === "") {
      navigate("/" + ROUTES.LOGIN);
    }
  }, [userToken]);

  return (
    <>
      <IdleTimeOutHandler
        timeOutInterval={IDLE_TIME_OUT}
        onActive={() => {
          setIsActive(true);
        }}
        onIdle={() => {
          setIsActive(false);
        }}
        onLogout={() => {
          userLogout();
        }}
      />
      <div style={{ minHeight: "90vh" }}>
        <AppHeader
          userName={firstName + " " + lastName}
          baseCurrency={baseCurrency}
          currentBalance={currentBalance}
          userLogout={userLogout}
        />
        <main>{children}</main>
      </div>
    </>
  );
};

AppLayout.propTypes = {
  children: element,
};

export default AppLayout;

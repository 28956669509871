import React from "react";
import { Outlet } from "react-router-dom";
import { AppLayout } from "components/app-layout";
import { AppFooter } from "components/app-footer";

const AppConsole = (props) => {
    return (
        <>
            <AppLayout>
            <>
               
                <div className="container-fluid mx-0 px-0">
                <div className="console-content">
                    <Outlet />
                </div>
                </div>
            </>
            </AppLayout>
            <AppFooter />
        </>
        );
};

AppConsole.propTypes = {};

export default AppConsole;

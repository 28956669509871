import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const dailyBillingList = createAsyncThunk(
  API_END_POINTS.BILLING_DAILY,
  async (payload, thunkAPI) => {
    try {
      const { month, year } = payload;
      const url =
        API_END_POINTS.BILLING_DAILY + "?month=" + month + "&year=" + year;
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const hourlyBillingList = createAsyncThunk(
  API_END_POINTS.BILLING_HOURLY,
  async (payload, thunkAPI) => {
    try {
      const { month, date, year } = payload;
      const url =
        API_END_POINTS.BILLING_HOURLY +
        "?month=" +
        month +
        "&date=" +
        date +
        "&year=" +
        year;
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTaxDetails = createAsyncThunk(
  API_END_POINTS.GET_TAX_DETAILS,
  async (payload, thunkAPI) => {
    try {
      const getTaxDetailsUrl = API_END_POINTS.GET_TAX_DETAILS;
      const res = await httpClient.get(getTaxDetailsUrl, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  isLoading: false,
  isDailyBillingListLoading: false,
  isHourlyBillingListLoading: false,
  isGetTaxDetailsLoading: false,
  dailyList: [],
  hourlyList: [],
  resource: "",
  totalAmount: "0.00",
  hourlyAmount: "0.00",
  taxPercentage: "",
  taxScheme: "",
  dailyBillingListErrorMessage: null,
  hourlyBillingListErrorMessage: null,
  getTaxDetailsErrorMessage: null,
};

export const billingSlice = createSlice({
  name: "billing",
  initialState: initialState,
  // non-async reducers
  reducers: {
    resetBilling: (state) => {
      state.isLoading = false;
      state.isDailyBillingListLoading = false;
      state.isHourlyBillingListLoading = false;
      state.isGetTaxDetailsLoading = false;
      state.dailyList = [];
      state.hourlyList = [];
      state.resource = "";
      state.totalAmount = "0.00";
      state.hourlyAmount = "0.00";
      state.taxPercentage = "";
      state.taxScheme = "";
      state.dailyBillingListErrorMessage = null;
      state.hourlyBillingListErrorMessage = null;
      state.getTaxDetailsErrorMessage = null;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(dailyBillingList.pending, (state, action) => {
      state.isDailyBillingListLoading = true;
    });
    builder.addCase(dailyBillingList.fulfilled, (state, action) => {
      const { data, resource, totalAmount } = action.payload || {};
      state.dailyList = data;
      state.resource = resource;
      state.totalAmount = totalAmount;
      state.isDailyBillingListLoading = false;
    });
    builder.addCase(dailyBillingList.rejected, (state, action) => {
      state.isDailyBillingListLoading = false;
      state.dailyBillingListErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(hourlyBillingList.pending, (state, action) => {
      state.isHourlyBillingListLoading = true;
    });
    builder.addCase(hourlyBillingList.fulfilled, (state, action) => {
      const { data, totalAmount } = action.payload || {};
      state.hourlyList = data;
      state.resource = "";
      state.hourlyAmount = totalAmount;
      state.isHourlyBillingListLoading = false;
    });
    builder.addCase(hourlyBillingList.rejected, (state, action) => {
      state.isHourlyBillingListLoading = false;
      state.hourlyBillingListErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(getTaxDetails.pending, (state, action) => {
      state.isGetTaxDetailsLoading = true;
    });
    builder.addCase(getTaxDetails.fulfilled, (state, action) => {
      const { status, taxPercentage, taxScheme } = action.payload || {};
      if (status === "success") {
        state.taxPercentage = taxPercentage;
        state.taxScheme = taxScheme;
        state.isGetTaxDetailsLoading = false;
      }
    });
    builder.addCase(getTaxDetails.rejected, (state, action) => {
      state.isGetTaxDetailsLoading = false;
      state.getTaxDetailsErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
  },
});

export const { resetBilling } = billingSlice.actions;

export default billingSlice.reducer;

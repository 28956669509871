import React, { useEffect, useState } from "react";
import moment from "moment";

const IdleTimeOutHandler = (props) => {
  const [isLogout, setLogout] = useState(false);
  let timer = undefined;
  const events = ["click", "load", "keydown", "mousemove"];
  const eventHandler = (eventType) => {
    if (!isLogout) {
      localStorage.setItem("lastInteractionTime", moment());
      if (timer) {
        props.onActive();
        startTimer();
      }
    }
  };

  useEffect(() => {
    addEvents();

    return () => {
      removeEvents();
      clearTimeout(timer);
    };
  }, []);

  const startTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(
      () => {
        let lastInteractionTime = localStorage.getItem("lastInteractionTime");
        const diff = moment.duration(
          moment().diff(moment(lastInteractionTime))
        );
        let timeOutInterval = props.timeOutInterval
          ? props.timeOutInterval
          : 6000;
        if (isLogout) {
          clearTimeout(timer);
        } else {
          if (diff._milliseconds < timeOutInterval) {
            startTimer();
            props.onActive();
            setLogout(false);
          } else {
            removeEvents();
            clearTimeout(timer);
            setLogout(true);
            props.onLogout();
          }
        }
      },
      props.timeOutInterval ? props.timeOutInterval : 6000
    );
  };
  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });

    startTimer();
  };

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  return <div></div>;
};

export default IdleTimeOutHandler;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_END_POINTS } from 'utils/constants';
import httpClient from 'utils/http-client';



export const listInstanceStats = createAsyncThunk(
    API_END_POINTS.INSTANCE_STATISTICS, async (payload, thunkAPI) => {
    try {
        const { id } = payload
        const { timeFrame } = payload
        const listStatsUrl = API_END_POINTS.INSTANCE_STATISTICS + '?time_frame=' + timeFrame
        const url = listStatsUrl.replace("{instance_id}", id.toString())
        const res = await httpClient.get(url, payload);
        return res.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});


const initialState = {
    statsList: [],
    isLoading: false,
    errorMessage:null,
}

export const statsSlice = createSlice({
  name: 'stats',
  initialState: initialState,
  // non-async reducers
  reducers: {},
  // async reducers
  extraReducers: builder => {
    builder.addCase(listInstanceStats.pending, (state, action) => {
        state.isLoading = true;
    })
    builder.addCase(listInstanceStats.fulfilled, (state, action) => {
      const { stats } = action.payload || {};
        state.isLoading = false;
        state.statsList = stats
    })
      builder.addCase(listInstanceStats.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.payload.response && action.payload.response.data.data ? action.payload.response.data.data : action.payload.response.data
    })
  }
});

export default statsSlice.reducer;
export const setData = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
  return value;
};

export const getData = (key) => {
  const saved = JSON.parse(localStorage.getItem(key));
  return saved;
};

export const removeData = (key) => {
  localStorage.removeItem(key);
  return true;
};

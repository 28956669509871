import React, { useEffect, useState } from "react";

const AppLoader = (props) => {
  const { isLoading, loaderSize = "lg" } = props;
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  useEffect(() => {
    setIsLoaderActive(isLoading);
  }, [isLoading]);
  return (
    <>
      {isLoaderActive && loaderSize === "lg" && (
        <div className="my-5">
          <div className="loader-container ">
            <div className="loader">
              <div className="spinner-border spinner-border-md spinner-border-light mx-2"></div>
            </div>
          </div>
        </div>
      )}
      {isLoaderActive && loaderSize !== "lg" && (
        <div className="loader-container-sm ">
          <div className="loader">
            <div className="spinner-border spinner-border-md spinner-border-light mx-2"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppLoader;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const instanceList = createAsyncThunk(
  API_END_POINTS.INSTANCES,
  async (payload, thunkAPI) => {
    try {
      const { id, active } = payload;
      const instanceListUrl =
        active === 1
          ? API_END_POINTS.INSTANCES + "?active=1"
          : API_END_POINTS.INSTANCES;
      const url = instanceListUrl.replace("{project_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const instanceCreation = createAsyncThunk(
  API_END_POINTS.INSTANCE_CREATION,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.post(
        API_END_POINTS.INSTANCE_CREATION,
        payload
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const instanceOverview = createAsyncThunk(
  API_END_POINTS.INSTANCES_OVERVIEW,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const instanceOverviewUrl = API_END_POINTS.INSTANCES_OVERVIEW;
      const url = instanceOverviewUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const instanceStart = createAsyncThunk(
  API_END_POINTS.INSTANCES_START,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const instanceStartUrl = API_END_POINTS.INSTANCES_START;
      const url = instanceStartUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.post(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const instanceStop = createAsyncThunk(
  API_END_POINTS.INSTANCES_STOP,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const instanceStopUrl = API_END_POINTS.INSTANCES_STOP;
      const url = instanceStopUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.post(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const instanceDestroy = createAsyncThunk(
  API_END_POINTS.INSTANCE_DESTROY,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const instanceDestroyUrl = API_END_POINTS.INSTANCE_DESTROY;
      const url = instanceDestroyUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.delete(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const instanceRename = createAsyncThunk(
  API_END_POINTS.INSTANCE_RENAME,
  async (params, thunkAPI) => {
    try {
      const { id, payload } = params;
      const instanceRenameUrl = API_END_POINTS.INSTANCE_RENAME;
      const url = instanceRenameUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.post(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getInstanceStatus = createAsyncThunk(
  API_END_POINTS.INSTANCE_STATUS,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const instanceListUrl = API_END_POINTS.INSTANCE_STATUS;
      const url = instanceListUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getPublicIp = createAsyncThunk(
  API_END_POINTS.INSTANCE_GET_PUBLIC_IP,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const getPublicIpUrl = API_END_POINTS.INSTANCE_GET_PUBLIC_IP;
      const url = getPublicIpUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetRootPassword = createAsyncThunk(
  API_END_POINTS.INSTANCE_RESET_ROOT_PASSWORD,
  async (params, thunkAPI) => {
    try {
      const { id, payload } = params;
      const resetRootPasswordUrl = API_END_POINTS.INSTANCE_RESET_ROOT_PASSWORD;
      const url = resetRootPasswordUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.put(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const instanceReboot = createAsyncThunk(
  API_END_POINTS.INSTANCE_REBOOT,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const instanceRebootUrl = API_END_POINTS.INSTANCE_REBOOT;
      const url = instanceRebootUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.post(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetSshKey = createAsyncThunk(
  API_END_POINTS.INSTANCE_RESET_SSH_KEY,
  async (params, thunkAPI) => {
    try {
      const { id, payload } = params;
      const resetSshKeyUrl = API_END_POINTS.INSTANCE_RESET_SSH_KEY;
      const url = resetSshKeyUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.put(url, { sshKeys: payload });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getinstanceUpgradePlans = createAsyncThunk(
  API_END_POINTS.INSTANCE_UPGRADE_PLANS,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const getinstanceUpgradePlansUrl = API_END_POINTS.INSTANCE_UPGRADE_PLANS;
      const url = getinstanceUpgradePlansUrl.replace(
        "{instance_id}",
        id.toString()
      );
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const instanceResize = createAsyncThunk(
  API_END_POINTS.INSTANCE_RESIZE,
  async (params, thunkAPI) => {
    try {
      const { id, payload } = params;
      const instanceResizeUrl = API_END_POINTS.INSTANCE_RESIZE;
      const url = instanceResizeUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.put(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const changeInstanceDefaultPrivateNetwork = createAsyncThunk(
  API_END_POINTS.INSTANCE_DEFAULT_PRIVATE_NETWORk,
  async (params, thunkAPI) => {
    try {
      const { instanceId, payloads } = params;
      const changeInstanceDefaultPrivateNetwork =
        API_END_POINTS.INSTANCE_DEFAULT_PRIVATE_NETWORK;
      const url = changeInstanceDefaultPrivateNetwork.replace(
        "{instance_id}",
        instanceId.toString()
      );
      const res = await httpClient.put(url, payloads);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  data: [],
  instancePlansUpgraded: [],
  overview: "",
  instancePassword: "",
  isSuccess: false,
  isLoading: false,
  isResizing: false,
  isResized: false,
  isStarted: false,
  isStopped: false,
  isDestroyed: false,
  isDestroying: false,
  isReboot: false,
  publicIp: "",
  publicGateway: "",
  publicSubnet: "",
  publicIpType: "",
  errorMessage: null,
  instanceListErrorMessage: null,
  instanceCreationErrorMessage: null,
  instanceOverviewErrorMessage: null,
  instanceStartErrorMessage: null,
  instanceStopErrorMessage: null,
  instanceDestroyErrorMessage: null,
  instanceRenameErrorMessage: null,
  getPublicIpErrorMessage: null,
  passwordChanged: false,
  isChangingPassword: false,
  getPasswordChangedErrorMessage: null,
  getInstanceRebootErrorMessage: null,
  isRebooting: false,
  statusResponse: null,
  isupdatingSshKey: false,
  sshKeyChanged: false,
  instanceId: 0,
  instanceStatusErrorMessage: null,
  statusCode: "",
  statusMessage: "",
  isPageLoading: false,
  isGetinstanceUpgradePlansLoading: false,
  resetSshKeyErrorMessage: null,
  instanceUpgradePlansErrorMessage: null,
  isResizingErrorMessage: null,
};

export const instanceSlice = createSlice({
  name: "instances",
  initialState: initialState,
  // non-async reducers
  reducers: {
    resetInstanceError: (state) => {
      state.instanceOverviewErrorMessage = null;
      state.getPasswordChangedErrorMessage = null;
      state.resetSshKeyErrorMessage = null;
      state.instanceStartErrorMessage = null;
      state.instanceStopErrorMessage = null;
      state.instanceRenameErrorMessage = null;
      state.instanceDestroyErrorMessage = null;
      state.instanceStatusErrorMessage = null;
      state.instanceCreationErrorMessage = null;
      state.instanceListErrorMessage = null;
      state.isResizingErrorMessage = null;
      state.changeInstanceDefaultPrivateNetworkErrorMessage = null;
    },
    resetinstance: (state) => {
      state.data = [];
      state.instancePassword = "";
      state.isSuccess = false;
      state.isLoading = false;
    },
    resetinstancePubIp: (state) => {
      state.publicIp = "";
      state.publicGateway = "";
      state.publicSubnet = "";
      state.publicIpType = "";
    },
    resetinstanceStatus: (state) => {
      state.isStarted = false;
      state.isStopped = false;
      state.isDestroyed = false;
      state.isDestroying = false;
      state.isRebooting = false;
      state.isReboot = false;
      state.passwordChanged = false;
      state.isResizing = false;
      state.isResized = false;
      state.instanceId = 0;
      state.statusResponse = null;
      state.isLoading = false;
      state.isGetinstanceUpgradePlansLoading = false;
      state.statusCode = "";
      state.statusMessage = "";
    },
    updateInstanceData: (state, param) => {
      const { payload } = param;
      const newState = state.data.map((obj) => {
        if (obj.id === parseInt(payload.id)) {
          return {
            ...obj,
            projectId: payload.projectId ? payload.projectId : "",
            percentage: payload.percentage ? payload.percentage : 0,
            colSpan: payload.colSpan ? payload.colSpan : "",
            showColumn: payload.showColumn ? payload.showColumn : false,
            instanceStatus: payload.status,
            instanceStatusMessage: payload.statusMessage,
          };
        }
        return obj;
      });
      state.data = newState;
    },
    updateInstanceDetails: (state, param) => {
      const { payload } = param;
      return {
        ...state,
        overview: {
          ...state.overview,
          instanceStatus: payload.status,
          instanceStatusMessage: payload.statusMessage,
        },
      };
    },
    refreshInstance: (state, param) => {
      const { payload } = param;
      state.data = state.data.filter((item) => item.id !== payload.id);
    },
    resetSshKeys: (state) => {
      state.sshKeyChanged = false;
    },
    resetDefaultPrivateNetwork: (state) => {
      state.isChanging = false;
      state.isChanged = false;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(instanceList.pending, (state, action) => {
      state.isPageLoading = true;
    });
    builder.addCase(instanceList.fulfilled, (state, action) => {
      const { instances } = action.payload || {};
      state.data = instances;
      state.isPageLoading = false;
    });
    builder.addCase(instanceList.rejected, (state, action) => {
      state.isPageLoading = false;
      state.instanceListErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(instanceCreation.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(instanceCreation.fulfilled, (state, action) => {
      const { instancePassword } = action.payload || {};
      state.instancePassword = instancePassword;
      state.isSuccess = true;
      state.isLoading = false;
    });
    builder.addCase(instanceCreation.rejected, (state, action) => {
      state.isLoading = false;
      state.instanceCreationErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(instanceOverview.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(instanceOverview.fulfilled, (state, action) => {
      state.overview = action.payload;
      state.isLoading = false;
      state.instanceId = action.meta.arg.id;
    });
    builder.addCase(instanceOverview.rejected, (state, action) => {
      state.isLoading = false;
      state.instanceOverviewErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
      state.instanceId = action.meta.arg.id;
    });

    builder.addCase(instanceStart.pending, (state, action) => {
      state.isLoading = true;
      state.isStarted = false;
      state.instanceId = action.meta.arg.id;
    });
    builder.addCase(instanceStart.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.statusCode = "start_initialised";
        state.statusMessage = "Starting";
        state.isStarted = true;
        state.isLoading = false;
        state.instanceId = action.meta.arg.id;
      }
    });
    builder.addCase(instanceStart.rejected, (state, action) => {
      state.isLoading = false;
      state.isStarted = false;
      state.instanceId = action.meta.arg.id;
      state.instanceStartErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(instanceStop.pending, (state, action) => {
      state.isLoading = true;
      state.isStopped = false;
      state.instanceId = action.meta.arg.id;
    });
    builder.addCase(instanceStop.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.statusCode = "stop_initialised";
        state.statusMessage = "Stopping";
        state.isStopped = true;
        state.isLoading = false;
        state.instanceId = action.meta.arg.id;
      }
    });
    builder.addCase(instanceStop.rejected, (state, action) => {
      state.isLoading = false;
      state.isStopped = false;
      state.instanceId = action.meta.arg.id;
      state.instanceStopErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(instanceDestroy.pending, (state, action) => {
      state.isDestroying = true;
      state.isDestroyed = false;
      state.instanceId = action.meta.arg.id;
    });
    builder.addCase(instanceDestroy.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.statusCode = "destroy_initialised";
        state.statusMessage = "Deleting";
        state.isDestroyed = true;
        state.isDestroying = false;
        state.instanceId = action.meta.arg.id;
      }
    });
    builder.addCase(instanceDestroy.rejected, (state, action) => {
      state.isDestroying = false;
      state.isDestroyed = false;
      state.instanceId = action.meta.arg.id;
      state.instanceDestroyErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(instanceRename.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(instanceRename.fulfilled, (state, action) => {
      state.isSuccess = true;
      state.isLoading = false;
    });
    builder.addCase(instanceRename.rejected, (state, action) => {
      state.isLoading = false;
      state.instanceRenameErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(getInstanceStatus.pending, (state, action) => {
      state.isLoading = true;
      state.instanceId = action.meta.arg.id;
    });
    builder.addCase(getInstanceStatus.fulfilled, (state, action) => {
      state.statusResponse = action.payload || {};
      state.instanceId = action.meta.arg.id;
      state.isLoading = false;
    });
    builder.addCase(getInstanceStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.instanceId = action.meta.arg.id;
      state.instanceStatusErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(getPublicIp.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getPublicIp.fulfilled, (state, action) => {
      const { status, ip, gateway, subnet, ipType } = action.payload || {};
      if (status === "success") {
        state.publicIp = ip;
        state.publicGateway = gateway;
        state.publicSubnet = subnet;
        state.publicIpType = ipType;
        state.isLoading = false;
      }
    });
    builder.addCase(getPublicIp.rejected, (state, action) => {
      state.isLoading = false;
      state.getPublicIpErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(resetRootPassword.pending, (state, action) => {
      state.isChangingPassword = true;
    });
    builder.addCase(resetRootPassword.fulfilled, (state, action) => {
      const { status, isReboot } = action.payload || {};
      if (status === "success") {
        state.passwordChanged = true;
        state.isChangingPassword = false;
        state.isReboot = isReboot;
      }
    });
    builder.addCase(resetRootPassword.rejected, (state, action) => {
      state.isChangingPassword = false;
      state.getPasswordChangedErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(instanceReboot.pending, (state, action) => {
      state.isLoading = true;
      state.instanceId = action.meta.arg.id;
    });
    builder.addCase(instanceReboot.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.statusCode = "reboot_initialised";
        state.statusMessage = "Rebooting";
        state.isLoading = false;
        state.isRebooting = true;
        state.instanceId = action.meta.arg.id;
      }
    });
    builder.addCase(instanceReboot.rejected, (state, action) => {
      state.isLoading = false;
      state.instanceId = action.meta.arg.id;
      state.getInstanceRebootErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(resetSshKey.pending, (state, action) => {
      state.isupdatingSshKey = true;
      state.sshKeyChanged = false;
    });
    builder.addCase(resetSshKey.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.sshKeyChanged = true;
        state.isupdatingSshKey = false;
      }
    });
    builder.addCase(resetSshKey.rejected, (state, action) => {
      state.isupdatingSshKey = false;
      state.sshKeyChanged = false;
      state.resetSshKeyErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
    builder.addCase(getinstanceUpgradePlans.pending, (state, action) => {
      state.isGetinstanceUpgradePlansLoading = true;
    });
    builder.addCase(getinstanceUpgradePlans.fulfilled, (state, action) => {
      const { instancePlans } = action.payload || {};
      state.isGetinstanceUpgradePlansLoading = false;
      state.instancePlansUpgraded = instancePlans;
    });
    builder.addCase(getinstanceUpgradePlans.rejected, (state, action) => {
      state.isGetinstanceUpgradePlansLoading = false;
      state.instanceUpgradePlansErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(instanceResize.pending, (state, action) => {
      state.isResizing = true;
      state.isResized = false;
    });
    builder.addCase(instanceResize.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.isResizing = false;
        state.isResized = true;
      }
    });
    builder.addCase(instanceResize.rejected, (state, action) => {
      state.isResizing = false;
      state.isResized = false;
      state.isResizingErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(
      changeInstanceDefaultPrivateNetwork.pending,
      (state, action) => {
        state.isChanging = true;
        state.isChanged = false;
      }
    );
    builder.addCase(
      changeInstanceDefaultPrivateNetwork.fulfilled,
      (state, action) => {
        const { status } = action.payload || {};
        if (status === "success") {
          state.isChanging = false;
          state.isChanged = true;
        }
      }
    );
    builder.addCase(
      changeInstanceDefaultPrivateNetwork.rejected,
      (state, action) => {
        state.isChanging = false;
        state.isChanged = false;
        // state.changeInstanceDefaultPrivateNetworkErrorMessage =
        //   action.payload.response && action.payload.response.data.data
        //     ? action.payload.response.data.data
        //     : action.payload.response.data;
      }
    );
  },
});

export const {
  resetInstanceError,
  resetinstance,
  resetinstanceStatus,
  updateInstanceData,
  updateInstanceDetails,
  resetSshKeys,
  refreshInstance,
  resetinstancePubIp,
  resetDefaultPrivateNetwork,
} = instanceSlice.actions;

export default instanceSlice.reducer;

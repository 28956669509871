import * as React from "react";
import { store } from "./store";
import { Routes, Route, Outlet, useNavigate, Navigate } from "react-router-dom";
import { AppDashboard } from "./components/app-dashboard";
import { AppInvalid } from "./components/app-invalid";
import { AppProject } from "./components/app-project";
import { ROUTES } from "./utils/constants";
import ProtectedRoutes from "./auth/protected-routes";
import { SetupInterceptors } from "./utils/http-client";
import { AppConsole } from "components/app-console";
import { useSelector } from "react-redux";
import { AppLoader } from "components/app-loader";

const LoginPage = React.lazy(() => import("./pages/login/Login.js"));

const ForgotPasswordPage = React.lazy(() =>
  import("./pages/forgot-password/ForgotPassword")
);
const ResetPasswordPage = React.lazy(() =>
  import("./pages/reset-password/ResetPassword")
);
const ResetPasswordSuccessPage = React.lazy(() =>
  import("./pages/reset-password/ResetPasswordSuccess")
);
const SignupPage = React.lazy(() => import("./pages/signup/Signup"));
const HomePage = React.lazy(() => import("./pages/home/Home"));
const AboutUsPage = React.lazy(() => import("./pages/home/AboutUs"));
const HelpCenterPage = React.lazy(() => import("./pages/home/HelpCenter"));
const PrivacyPage = React.lazy(() => import("./pages/home/PrivacyPolicy"));
const CookiesPage = React.lazy(() => import("./pages/home/CookiesPolicy"));
const TermsAndConditionsPage = React.lazy(() =>
  import("./pages/home/TermsAndConditions")
);
const ProjectsPage = React.lazy(() => import("./pages/projects/Projects"));
const ProjectDetailPage = React.lazy(() =>
  import("./pages/project-details/ProjectDetails")
);
const LimitsPage = React.lazy(() => import("./pages/limits/Limits"));
const ActivitiesPage = React.lazy(() =>
  import("./pages/activities/Activities")
);
const RequisitionsPgae = React.lazy(() => import("./pages/requisition/Requisition"));
const ServersPage = React.lazy(() => import("./pages/servers/Server"));
const ServerCreatePage = React.lazy(() =>
  import("./pages/server-create-form/ServerCreateForm")
);
const ServerDetailPage = React.lazy(() =>
  import("./pages/server-detail/ServerDetail")
);
const VolumesPage = React.lazy(() => import("./pages/volumes/Volumes"));
const NetworksPage = React.lazy(() => import("./pages/networks/Networks"));
const ImagesPage = React.lazy(() => import("./pages/images/Images"));
const DnsPage = React.lazy(() => import("./pages/dns/Dns"));
const UsersPage = React.lazy(() => import("./pages/users/Users"));
const EventsPage = React.lazy(() => import("./pages/events/Events"));
const DeletePage = React.lazy(() =>
  import("./pages/project-delete/ProjectDelete")
);
const AccountSettingsPage = React.lazy(() =>
  import("./pages/account-settings/AccountSettings")
);
const AccountPayementGatewayPage = React.lazy(() =>
  import("./pages/payment-gateway/PaymentGateway")
);
const FloatingIpPage = React.lazy(() =>
  import("./pages/floating-ip/FloatingIP")
);
const ServerConsolePage = React.lazy(() =>
  import("./pages/server-detail/server-console/ServerConsole")
);
const PaymentSuccessPage = React.lazy(() =>
  import("./pages/payment-success/PaymentSuccess")
);

const LazyLoad = ({ Comp }) => {
  return (
    <React.Suspense fallback={<AppLoader isLoading={true} />}>
      <Comp />
    </React.Suspense>
  );
};

export default function App() {
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.user);
  SetupInterceptors(store, navigate);
  return (
    <div>
      <Routes>
        <Route
          path={ROUTES.RESETPASSWORD_SUCCESS}
          element={<LazyLoad Comp={ResetPasswordSuccessPage} />}
        />
        <Route path={ROUTES.LOGIN} element={<LazyLoad Comp={LoginPage} />} />
        <Route
          path={ROUTES.FORGOTPASSWORD}
          element={<LazyLoad Comp={ForgotPasswordPage} />}
        />
        <Route path={ROUTES.SIGNUP} element={<LazyLoad Comp={SignupPage} />} />
        <Route
          path={ROUTES.RESETPASSWORD}
          element={<LazyLoad Comp={ResetPasswordPage} />}
        ></Route>

        <Route path={ROUTES.DEFAULT} element={<Outlet />}>
          <Route path={ROUTES.DEFAULT} element={<LazyLoad Comp={HomePage} />} />
          <Route
            path={ROUTES.TERMS_AND_CONDITIONS}
            element={<LazyLoad Comp={TermsAndConditionsPage} />}
          />
          <Route
            path={ROUTES.PRIVACY_POLICY}
            element={<LazyLoad Comp={PrivacyPage} />}
          />
          <Route
            path={ROUTES.COOKIES_POLICY}
            element={<LazyLoad Comp={CookiesPage} />}
          />
          <Route
            path={ROUTES.ABOUT_US}
            element={<LazyLoad Comp={AboutUsPage} />}
          />
          <Route
            path={ROUTES.HELP_CENTER}
            element={<LazyLoad Comp={HelpCenterPage} />}
          />
          <Route
            path={ROUTES.PROJECT}
            element={<LazyLoad Comp={AppDashboard} />}
          >
            <Route
              path=""
              element={
                <ProtectedRoutes>
                  <LazyLoad Comp={ProjectsPage} />
                </ProtectedRoutes>
              }
            />
          </Route>
          <Route
            path={ROUTES.LIMITS}
            element={<LazyLoad Comp={AppDashboard} />}
          >
            <Route
              path=""
              element={
                <ProtectedRoutes>
                  <LazyLoad Comp={LimitsPage} />
                </ProtectedRoutes>
              }
            />
          </Route>
          <Route
            path={ROUTES.ACTIVITIES}
            element={<LazyLoad Comp={AppDashboard} />}
          >
            <Route
              path=""
              element={
                <ProtectedRoutes>
                  <LazyLoad Comp={ActivitiesPage} />
                </ProtectedRoutes>
              }
            />
          </Route>
          <Route
            path={ROUTES.ACCOUNT_SETTINGS}
            element={<LazyLoad Comp={AppDashboard} />}
          >
            <Route
              path=""
              element={
                <ProtectedRoutes>
                  <LazyLoad Comp={AccountSettingsPage} />
                </ProtectedRoutes>
              }
            />
          </Route>
          <Route
            path={ROUTES.ACCOUNT_PAYMENT}
            element={<LazyLoad Comp={AppDashboard} />}
          >
            <Route
              path=""
              element={
                <ProtectedRoutes>
                  <LazyLoad Comp={AccountSettingsPage} />
                </ProtectedRoutes>
              }
            />
          </Route>

          <Route
            path={ROUTES.ACCOUNT_USAGE}
            element={<LazyLoad Comp={AppDashboard} />}
          >
            <Route
              path=""
              element={
                <ProtectedRoutes>
                  <LazyLoad Comp={AccountSettingsPage} />
                </ProtectedRoutes>
              }
            />
          </Route>
          <Route
            path={ROUTES.ACCOUNT_SSHKEY}
            element={<LazyLoad Comp={AppDashboard} />}
          >
            <Route
              path=""
              element={
                <ProtectedRoutes>
                  <LazyLoad Comp={AccountSettingsPage} />
                </ProtectedRoutes>
              }
            />
          </Route>
          <Route
            path={ROUTES.ACCOUNT_USAGE_HOURLY}
            element={<LazyLoad Comp={AppDashboard} />}
          >
            <Route
              path=""
              element={
                <ProtectedRoutes>
                  <LazyLoad Comp={AccountSettingsPage} />
                </ProtectedRoutes>
              }
            />
          </Route>
          <Route
            path={ROUTES.ACCOUNT_PAYMENT_GATEWAY}
            element={<LazyLoad Comp={AppDashboard} />}
          >
            <Route
              path=""
              element={
                <ProtectedRoutes>
                  <LazyLoad Comp={AccountPayementGatewayPage} />
                </ProtectedRoutes>
              }
            />
          </Route>

          <Route
            path={ROUTES.PROJECT}
            element={
              <ProtectedRoutes>
                <LazyLoad Comp={AppProject} />
              </ProtectedRoutes>
            }
          >
            <Route
              path=":id"
              element={
                <ProtectedRoutes>
                  <LazyLoad Comp={ProjectDetailPage} />
                </ProtectedRoutes>
              }
            >
              <Route
                path={ROUTES.REQUISITION}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={RequisitionsPgae} key={Date.now()} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.SERVERS}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={ServersPage} key={Date.now()} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.SERVER_CREATE}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={ServerCreatePage} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.SERVER_DETAIL + ROUTES.SERVER_OVERVIEW}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={ServerDetailPage} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.SERVER_DETAIL + ROUTES.SERVER_NETWORK}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={ServerDetailPage} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.SERVER_DETAIL + ROUTES.SERVER_VOLUMES}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={ServerDetailPage} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.SERVER_DETAIL + ROUTES.SERVER_BACKUPS}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={ServerDetailPage} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.SERVER_DETAIL + ROUTES.SERVER_FIREWALL}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={ServerDetailPage} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.SERVER_DETAIL + ROUTES.SERVER_STATISTICS}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={ServerDetailPage} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.SERVER_DETAIL + ROUTES.SERVER_EVENTS}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={ServerDetailPage} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.SERVER_DETAIL + ROUTES.SERVER_SNAPSHOT}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={ServerDetailPage} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.SERVER_DETAIL + ROUTES.SERVER_ACCESS}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={ServerDetailPage} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.SERVER_DETAIL + ROUTES.SERVER_RESIZE}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={ServerDetailPage} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.SERVER_DETAIL + ROUTES.SERVER_DESTROY}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={ServerDetailPage} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.NETWORKS}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={NetworksPage} key={Date.now()} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.VOLUMES}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={VolumesPage} key={Date.now()} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.IMAGES}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={ImagesPage} key={Date.now()} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.FLOATING_IP}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={FloatingIpPage} key={Date.now()} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.DNS}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={DnsPage} key={Date.now()} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.USERS}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={UsersPage} key={Date.now()} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.EVENTS}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={EventsPage} key={Date.now()} />
                  </ProtectedRoutes>
                }
              />
              <Route
                path={ROUTES.DELETE}
                element={
                  <ProtectedRoutes>
                    <LazyLoad Comp={DeletePage} key={Date.now()} />
                  </ProtectedRoutes>
                }
              />
            </Route>
          </Route>
          <Route
            path={ROUTES.DEFAULT}
            element={
              <ProtectedRoutes>
                <LazyLoad Comp={AppConsole} />
              </ProtectedRoutes>
            }
          >
            <Route
              path={ROUTES.SERVER_DETAIL + ROUTES.SERVER_CONSOLE}
              element={
                <ProtectedRoutes>
                  <LazyLoad Comp={ServerConsolePage} />
                </ProtectedRoutes>
              }
            />
          </Route>
          <Route
            path={ROUTES.PAYMENT_SUCCESS}
            element={
              <ProtectedRoutes>
                <LazyLoad Comp={PaymentSuccessPage} />
              </ProtectedRoutes>
            }
          />
          <Route path="*" element={<LazyLoad Comp={AppInvalid} />} />
        </Route>
      </Routes>
    </div>
  );
}

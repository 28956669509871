import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const getPaymentHistory = createAsyncThunk(
  API_END_POINTS.PAYEMNT_HISTORY,
  async (payload, thunkAPI) => {
    try {
      const url = API_END_POINTS.PAYEMNT_HISTORY;
      const res = await httpClient.get(url);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getWalletBalance = createAsyncThunk(
  API_END_POINTS.WALLET_BALANCE,
  async (payload, thunkAPI) => {
    try {
      const url = API_END_POINTS.WALLET_BALANCE;
      const res = await httpClient.get(url);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addDpoPayment = createAsyncThunk(
  API_END_POINTS.ADD_DPO_PAYMENT,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.post(
        API_END_POINTS.ADD_DPO_PAYMENT,
        payload
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const verifyPayment = createAsyncThunk(
  API_END_POINTS.VERIFY_PAYMENT,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.post(API_END_POINTS.VERIFY_PAYMENT, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getPaymentGatewayList = createAsyncThunk(
  API_END_POINTS.PAYEMNT_GATEWAY_LIST,
  async (payload, thunkAPI) => {
    try {
      const getPaymentGatewayListUrl = API_END_POINTS.PAYEMNT_GATEWAY_LIST;
      const res = await httpClient.get(getPaymentGatewayListUrl);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addMpesaPayment = createAsyncThunk(
  API_END_POINTS.ADD_MPESA_PAYMENT,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.post(
        API_END_POINTS.ADD_MPESA_PAYMENT,
        payload
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  paymentList: [],
  paymenGatewaytList: [],
  currentBalance: "0.00000",
  paymentRedirectUrl: "",
  paymentInitiated: false,
  isLoadingPaymentList: false,
  paymentGatewayListLoading: false,
  isGetPaymentHistoryLoading: false,
  isLoadingWalletBalance: false,
  isLoadingAddPayment: false,
  isLoadingVerifyPayment: false,
  isPaymentVerified: false,
  paymentListErrorMessage: null,
  addPaymentErrorMessage: null,
  verifyPaymentErrorMessage: null,
  paymenGatewaytListErrorMessage: null,
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState: initialState,
  // non-async reducers
  reducers: {
    resetPayment: (state) => {
      state.paymentList = [];
      state.paymenGatewaytList = [];
      state.paymentRedirectUrl = "";
      state.paymentInitiated = false;
    },
    resetPaymentErrors: (state) => {
      state.paymentListErrorMessage = null;
      state.addPaymentErrorMessage = null;
      state.verifyPaymentErrorMessage = null;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(getPaymentHistory.pending, (state, action) => {
      state.isGetPaymentHistoryLoading = true;
    });
    builder.addCase(getPaymentHistory.fulfilled, (state, action) => {
      const { paymentList } = action.payload || {};
      state.paymentList = paymentList;
      state.isGetPaymentHistoryLoading = false;
    });
    builder.addCase(getPaymentHistory.rejected, (state, action) => {
      state.isGetPaymentHistoryLoading = false;
      state.paymentListErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(getWalletBalance.pending, (state, action) => {
      state.isLoadingWalletBalance = true;
    });
    builder.addCase(getWalletBalance.fulfilled, (state, action) => {
      const { currentBalance } = action.payload || {};
      state.currentBalance = currentBalance;
      state.isLoadingWalletBalance = false;
    });
    builder.addCase(getWalletBalance.rejected, (state, action) => {
      state.isLoadingWalletBalance = false;
      state.paymentListErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(addDpoPayment.pending, (state, action) => {
      state.isLoadingAddPayment = true;
    });
    builder.addCase(addDpoPayment.fulfilled, (state, action) => {
      const { status, paymentUrl } = action.payload;
      if (status === "success") {
        state.paymentRedirectUrl = paymentUrl;
        state.isLoadingAddPayment = false;
      }
    });
    builder.addCase(addDpoPayment.rejected, (state, action) => {
      state.isLoadingAddPayment = false;
      state.addPaymentErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(verifyPayment.pending, (state, action) => {
      state.isLoadingVerifyPayment = true;
    });
    builder.addCase(verifyPayment.fulfilled, (state, action) => {
      const { status } = action.payload;
      if (status === "success") {
        state.isPaymentVerified = true;
        state.isLoadingVerifyPayment = false;
      }
    });
    builder.addCase(verifyPayment.rejected, (state, action) => {
      state.isLoadingVerifyPayment = false;
      state.verifyPaymentErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(getPaymentGatewayList.pending, (state, action) => {
      state.paymentGatewayListLoading = true;
    });
    builder.addCase(getPaymentGatewayList.fulfilled, (state, action) => {
      const { status, pgList } = action.payload || {};
      if (status === "success") {
        state.paymenGatewaytList = pgList;
        state.paymentGatewayListLoading = false;
      }
    });
    builder.addCase(getPaymentGatewayList.rejected, (state, action) => {
      state.paymentGatewayListLoading = false;
      state.paymenGatewaytListErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(addMpesaPayment.pending, (state, action) => {
      state.isLoadingAddPayment = true;
    });
    builder.addCase(addMpesaPayment.fulfilled, (state, action) => {
      const { status } = action.payload;
      if (status === "success") {
        state.paymentInitiated = true;
        state.isLoadingAddPayment = false;
      }
    });
    builder.addCase(addMpesaPayment.rejected, (state, action) => {
      state.isLoadingAddPayment = false;
      state.addPaymentErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
  },
});

export const { resetPaymentErrors, resetPayment } = paymentSlice.actions;

export default paymentSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_END_POINTS } from 'utils/constants';
import httpClient from 'utils/http-client';

export const planList = createAsyncThunk(API_END_POINTS.PLANS, async (payload, thunkAPI) => {
  try {
    const { regionId } = payload
    const planListUrl = API_END_POINTS.PLANS
    const url = planListUrl.replace("{region_id}", regionId.toString())
    const res = await httpClient.get(url, payload);
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState = {
    data: [],
    isLoading: false,
    errorMessage:null,
}

export const planSlice = createSlice({
  name: 'plans',
  initialState: initialState,
  // non-async reducers
  reducers: {},
  // async reducers
  extraReducers: builder => {
    builder.addCase(planList.pending, (state, action) => {
        state.isLoading = true;
    })
    builder.addCase(planList.fulfilled, (state, action) => {
      const { instancePlans } = action.payload || {};
        state.isLoading = false;
        state.data = instancePlans
    })
      builder.addCase(planList.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.payload.response && action.payload.response.data.data ? action.payload.response.data.data : action.payload.response.data
    })
  }
});

export default planSlice.reducer;



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const getCountries = createAsyncThunk(
  API_END_POINTS.COUNTRIES,
  async (payload, thunkAPI) => {
    try {
      const url = API_END_POINTS.COUNTRIES;
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getState = createAsyncThunk(
  API_END_POINTS.STATE,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const getStateUrl = API_END_POINTS.STATE;
      const url = getStateUrl.replace("{country_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  countryList: null,
  stateList: null,
  isLoading: false,
  countryListErrorMessage: null,
  stateListErrorMessage: null,
};

export const locationsSlice = createSlice({
  name: "locations",
  initialState: initialState,
  // non-async reducers
  reducers: {},
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(getCountries.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      const { countryList } = action.payload || {};
      state.countryList = countryList;
      state.isLoading = false;
    });
    builder.addCase(getCountries.rejected, (state, action) => {
      state.isLoading = false;
      state.countryListErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(getState.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getState.fulfilled, (state, action) => {
      const { stateList } = action.payload || {};
      state.stateList = stateList;
      state.isLoading = false;
    });
    builder.addCase(getState.rejected, (state, action) => {
      state.isLoading = false;
      state.stateListErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
  },
});

export default locationsSlice.reducer;

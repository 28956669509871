import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const projectNetworks = createAsyncThunk(
  API_END_POINTS.NETWORKS,
  async (payload, thunkAPI) => {
    try {
      const { id, zone } = payload;
      const projectNetworksUrl =
        zone === 0
          ? API_END_POINTS.NETWORKS
          : API_END_POINTS.NETWORKS + "?location={zone}";
      const url =
        zone === 0
          ? projectNetworksUrl.replace("{project_id}", id.toString())
          : projectNetworksUrl
              .replace("{project_id}", id.toString())
              .replace("{zone}", zone.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const networkCreation = createAsyncThunk(
  API_END_POINTS.NETWORK_CREATION,
  async (params, thunkAPI) => {
    try {
      const { id, payload } = params;
      const networkCreationUrl = API_END_POINTS.NETWORKS;
      const url = networkCreationUrl.replace("{project_id}", id.toString());
      const res = await httpClient.post(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const networkCidr = createAsyncThunk(
  API_END_POINTS.NETWORK_CIDR,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const networkCidrUrl = API_END_POINTS.NETWORK_CIDR;
      const url = networkCidrUrl.replace("{project_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const networkStatus = createAsyncThunk(
  API_END_POINTS.NETWORK_STATUS,
  async (payload, thunkAPI) => {
    try {
      const { id, instanceId } = payload;
      const networkStatusUrl =
        instanceId === 0
          ? API_END_POINTS.NETWORK_STATUS
          : API_END_POINTS.NETWORK_STATUS + "?id={instance_id}";
      const url =
        instanceId === 0
          ? networkStatusUrl.replace("{network_id}", id.toString())
          : networkStatusUrl
              .replace("{network_id}", id.toString())
              .replace("{instance_id}", instanceId.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteNetwork = createAsyncThunk(
  API_END_POINTS.NETWORK_DELETE,
  async (payload, thunkAPI) => {
    try {
      const { networkId } = payload;
      const deleteNetworkUrl = API_END_POINTS.NETWORK_DELETE;
      const url = deleteNetworkUrl.replace(
        "{network_id}",
        networkId.toString()
      );
      const res = await httpClient.delete(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const instanceNetworks = createAsyncThunk(
  API_END_POINTS.INSTANCES_NETWORKS,
  async (payload, thunkAPI) => {
    try {
      const { instanceId } = payload;
      const instanceNetworksUrl = API_END_POINTS.INSTANCES_NETWORKS;
      const url = instanceNetworksUrl.replace(
        "{instance_id}",
        instanceId.toString()
      );
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const attachNetworks = createAsyncThunk(
  API_END_POINTS.NETWORK_ATTACH,
  async (payload, thunkAPI) => {
    try {
      const { networkId, payloads } = payload;
      const attachNetworksUrl = API_END_POINTS.NETWORK_ATTACH;
      const url = attachNetworksUrl.replace(
        "{network_id}",
        networkId.toString()
      );
      const res = await httpClient.post(url, payloads);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const detachNetworks = createAsyncThunk(
  API_END_POINTS.NETWORK_DETACH,
  async (payload, thunkAPI) => {
    try {
      const { networkId, payloads } = payload;
      const detachNetworksUrl = API_END_POINTS.NETWORK_DETACH;
      const url = detachNetworksUrl.replace(
        "{network_id}",
        networkId.toString()
      );
      const res = await httpClient.post(url, payloads);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const validateNetworkip = createAsyncThunk(
  API_END_POINTS.NETWORK_IP_VALIDATE,
  async (payload, thunkAPI) => {
    try {
      const { networkId, payloads } = payload;
      const validateNetworkipUrl = API_END_POINTS.NETWORK_IP_VALIDATE;
      const url = validateNetworkipUrl.replace(
        "{network_id}",
        networkId.toString()
      );
      const res = await httpClient.post(url, payloads);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  isPageLoading: false,
  isInstanceNetworksLoading: false,
  isCreating: false,
  isSuccess: false,
  isLoading: false,
  isDeleteSuccess: false,
  isAttachSuccess: false,
  isDetachSuccess: false,
  isValidateSuccess: false,
  isValidating: false,
  projectNetworksList: [],
  instanceNetworksList: [],
  maxAllowedCidr: "",
  defaultCidr: "",
  networkStatusResponse: null,
  networkId: "",
  statusCode: "",
  statusMessage: "",
  projectNetworksListErrorMessage: null,
  instanceNetworksListErrorMessage: null,
  networkCreationErrors: null,
  floatingIpDeleteErrors: null,
  networkCidrErrorMessage: null,
  networkStatusErrorMessage: null,
  deleteNetworkErrors: null,
  networkAttachErrors: null,
  networkDetachErrors: null,
  validateNetworkipErrors: null,
};

export const networkSlice = createSlice({
  name: "network",
  initialState: initialState,
  // non-async reducers
  reducers: {
    resetNetworks: (state) => {
      state.projectNetworksList = [];
      state.networkStatusResponse = null;
      state.maxAllowedCidr = "";
      state.defaultCidr = "";
      state.isLoading = false;
      state.isInstanceNetworksLoading = false;
      state.isCreating = false;
      state.isSuccess = false;
      state.isValidateSuccess = false;
      state.isValidating = false;
    },
    refreshProjectNetwork: (state, param) => {
      const { payload } = param;
      state.projectNetworksList = state.projectNetworksList.filter(
        (item) => item.id !== payload.id
      );
    },
    resetNetworkErrors: (state) => {
      state.networkCreationErrors = null;
      state.projectNetworksListErrorMessage = null;
      state.networkCidrErrorMessage = null;
      state.networkStatusErrorMessage = null;
      state.deleteNetworkErrors = null;
      state.networkAttachErrors = null;
      state.networkDetachErrors = null;
      state.validateNetworkipErrors = null;
    },

    resetNetworkActions: (state) => {
      state.statusMessage = "";
      state.networkStatusResponse = null;
      state.isDeleteSuccess = false;
      state.isDetachSuccess = false;
      state.isAttachSuccess = false;
      state.isSuccess = false;
      state.networkId = 0;
      state.statusCode = "";
      state.statusMessage = "";
    },
    updateNetworkData: (state, param) => {
      const { payload } = param;
      const newState = state.projectNetworksList.map((obj) => {
        if (obj.id === parseInt(payload.networkId)) {
          return {
            ...obj,
            name: payload.name ? payload.name : obj.name,
            subnet: payload.subnet ? payload.subnet : obj.subnet,
            createdDate: payload.createdDate
              ? payload.createdDate
              : obj.createdDate,
            statusCode: payload.statusCode,
            status: payload.statusCode,
            statusMessage: payload.statusMessage,
          };
        }
        return obj;
      });
      state.projectNetworksList = newState;
    },
    updateInstanceNetworkData: (state, param) => {
      const { payload } = param;
      const newState = state.instanceNetworksList.map((obj) => {
        if (obj.id === parseInt(payload.networkId)) {
          return {
            ...obj,
            name: payload.name ? payload.name : obj.name,
            subnet: payload.subnet ? payload.subnet : obj.subnet,
            createdDate: payload.createdDate
              ? payload.createdDate
              : obj.createdDate,
            isPublic: payload.isPublic,
            statusCode: payload.statusCode,
            status: payload.statusCode,
            statusMessage: payload.statusMessage,
          };
        }
        return obj;
      });
      state.instanceNetworksList = newState;
    },
    resetInstanceNetworks: (state) => {
      state.isValidateSuccess = false;
      state.isValidating = false;
    },
    refreshInstanceNetwork: (state, param) => {
      const { payload } = param;
      state.instanceNetworksList = state.instanceNetworksList.filter(
        (item) => item.id !== payload.id
      );
    },
    resetIPValidateSucces: (state) => {
      state.isValidateSuccess = false;
      state.isValidating = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(projectNetworks.pending, (state, action) => {
      state.isPageLoading = true;
    });
    builder.addCase(projectNetworks.fulfilled, (state, action) => {
      const { networks } = action.payload || {};
      state.projectNetworksList = networks;
      state.isPageLoading = false;
    });
    builder.addCase(projectNetworks.rejected, (state, action) => {
      state.isPageLoading = false;
      state.projectNetworksListErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(networkCreation.pending, (state, action) => {
      state.isCreating = true;
    });
    builder.addCase(networkCreation.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.isSuccess = true;
        state.isCreating = false;
      }
    });
    builder.addCase(networkCreation.rejected, (state, action) => {
      state.isCreating = false;
      state.networkCreationErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(networkCidr.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(networkCidr.fulfilled, (state, action) => {
      const { status, maxAllowedCidr, defaultCidr } = action.payload || {};
      if (status === "success") {
        state.maxAllowedCidr = maxAllowedCidr;
        state.defaultCidr = defaultCidr;
        state.isLoading = false;
      }
    });
    builder.addCase(networkCidr.rejected, (state, action) => {
      state.isLoading = false;
      state.networkCidrErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(networkStatus.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(networkStatus.fulfilled, (state, action) => {
      state.networkStatusResponse = action.payload || {};
      state.networkId = action.meta.arg.id;
      state.isStatusSuccess = true;
      state.isLoading = false;
    });
    builder.addCase(networkStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.networkId = action.meta.arg.id;
      state.networkStatusErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(deleteNetwork.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteNetwork.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.statusCode = "delete_initialised";
        state.statusMessage = "Deleting";
        state.networkId = action.meta.arg.networkId;
        state.isDeleteSuccess = true;
        state.isLoading = false;
      }
    });
    builder.addCase(deleteNetwork.rejected, (state, action) => {
      state.isLoading = false;
      state.deleteNetworkErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(instanceNetworks.pending, (state, action) => {
      state.isInstanceNetworksLoading = true;
    });
    builder.addCase(instanceNetworks.fulfilled, (state, action) => {
      const { networks } = action.payload || {};
      state.instanceNetworksList = networks;
      state.isInstanceNetworksLoading = false;
    });
    builder.addCase(instanceNetworks.rejected, (state, action) => {
      state.isInstanceNetworksLoading = false;
      state.instanceNetworksListErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(attachNetworks.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(attachNetworks.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.statusCode = "attach_initialised";
        state.statusMessage = "Attaching";
        state.isAttachSuccess = true;
        state.isLoading = false;
        state.networkId = action.meta.arg.networkId;
      }
    });
    builder.addCase(attachNetworks.rejected, (state, action) => {
      state.isLoading = false;
      state.networkId = action.meta.arg.networkId;
      state.networkAttachErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(detachNetworks.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(detachNetworks.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.statusCode = "detach_initialised";
        state.statusMessage = "Detaching";
        state.isDetachSuccess = true;
        state.isLoading = false;
        state.networkId = action.meta.arg.networkId;
      }
    });
    builder.addCase(detachNetworks.rejected, (state, action) => {
      state.isLoading = false;
      state.networkDetachErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(validateNetworkip.pending, (state, action) => {
      state.isValidating = true;
    });
    builder.addCase(validateNetworkip.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.isValidateSuccess = true;
        state.isValidating = false;
      }
    });
    builder.addCase(validateNetworkip.rejected, (state, action) => {
      state.isValidating = false;
      state.validateNetworkipErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
  },
});

export const {
  refreshProjectNetwork,
  resetNetworkErrors,
  resetNetworks,
  resetNetworkActions,
  updateNetworkData,
  updateInstanceNetworkData,
  refreshInstanceNetwork,
  resetInstanceNetworks,
  resetIPValidateSucces,
} = networkSlice.actions;

export default networkSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const getPrice = createAsyncThunk(
  API_END_POINTS.PRICE,
  async (payload, thunkAPI) => {
    try {
      const { resource } = payload;
      const url = API_END_POINTS.PRICE + "?resource=" + resource;
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  resourceData: null,
  isLoading: false,
  resourceDataErrorMessage: null,
};

export const resourceSlice = createSlice({
  name: "resource",
  initialState: initialState,
  // non-async reducers
  reducers: {
    resetResourceData: (state) => {
      state.resourceData = null;
      state.isLoading = false;
      state.resourceDataErrorMessage = null;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(getPrice.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getPrice.fulfilled, (state, action) => {
      state.resourceData = action.payload || {};
      state.isLoading = false;
    });
    builder.addCase(getPrice.rejected, (state, action) => {
      state.isLoading = false;
      state.resourceDataErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
  },
});

export const { resetResourceData } = resourceSlice.actions;

export default resourceSlice.reducer;

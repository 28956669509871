import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const projectList = createAsyncThunk(
  API_END_POINTS.PROJECTS_SUMMARY,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.get(
        API_END_POINTS.PROJECTS_SUMMARY,
        payload
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const projectCreation = createAsyncThunk(
  API_END_POINTS.PROJECTS,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.post(API_END_POINTS.PROJECTS, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const acceptInvitation = createAsyncThunk(
  API_END_POINTS.PROJECT_ACCEPT_INVITATION,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const acceptInvitationUrl = API_END_POINTS.PROJECT_ACCEPT_INVITATION;
      const url = acceptInvitationUrl.replace("{project_id}", id.toString());
      const res = await httpClient.put(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const declineInvitation = createAsyncThunk(
  API_END_POINTS.PROJECT_DENY_INVITATION,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const acceptInvitationUrl = API_END_POINTS.PROJECT_DENY_INVITATION;
      const url = acceptInvitationUrl.replace("{project_id}", id.toString());
      const res = await httpClient.put(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const projectDelete = createAsyncThunk(
  API_END_POINTS.PROJECTS_DELETE,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const projectDeleteUrl = API_END_POINTS.PROJECTS_DELETE;
      const url = projectDeleteUrl.replace("{project_id}", id.toString());
      const res = await httpClient.delete(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const requisitionCount = createAsyncThunk(
  API_END_POINTS.REQUISITION_COUNT,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;      
      const requisitionCountUrl = API_END_POINTS.REQUISITION_COUNT;
      const url = requisitionCountUrl.replace("{project_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const requisitionList = createAsyncThunk(
  API_END_POINTS.REQUISITION_LIST,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;   
      const requisitionListUrl = API_END_POINTS.REQUISITION_LIST;
      const url = requisitionListUrl.replace("{project_id}", id.toString());
      const res = await httpClient.get(url, payload);      
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const requisitionApprove = createAsyncThunk(
  API_END_POINTS.REQUISITION_APPROVE,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;   
      const requisitionApproveUrl = API_END_POINTS.REQUISITION_APPROVE;
      const url = requisitionApproveUrl.replace("{requisition_id}", id.toString());
      const res = await httpClient.put(url, payload);      
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const requisitionCancel = createAsyncThunk(
  API_END_POINTS.REQUISITION_CANCEL,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;   
      const requisitionCancelUrl = API_END_POINTS.REQUISITION_CANCEL;
      const url = requisitionCancelUrl.replace("{requisition_id}", id.toString());
      const res = await httpClient.put(url, payload);      
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  data: [],
  reqList: [],
  isLoading: false,
  isProjectListLoading: false,
  isRequisitionLoading: false,
  message: "",
  projectId: 0,
  reqCount: 0,
  isCreated: false,
  isDeleted: false,
  isDeleting: false,
  isApproved: false,
  isCancelled: false,
  errorMessage: null,
  projectDeleteErrorMessage: null,
  requisitionErrorMessage: null,
  requisitionListErrorMessage: null,
  isInvitationAccepted: false,
  isInvitationLoading: false,
  isInvitationDenied: false,
};

export const projectSlice = createSlice({
  name: "projects",
  initialState: initialState,
  // non-async reducers
  reducers: {
    resetProjectError: (state) => {
      state.projectDeleteErrorMessage = null;
      state.errorMessage = null;
      state.requisitionErrorMessage = null;
      state.requisitionListErrorMessage = null;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(projectList.pending, (state, action) => {
      state.isProjectListLoading = true;
    });
    builder.addCase(projectList.fulfilled, (state, action) => {
      const { projects } = action.payload || {};
      state.isProjectListLoading = false;
      state.data = projects;
    });
    builder.addCase(projectList.rejected, (state, action) => {
      state.isProjectListLoading = false;
      state.errorMessage = action.payload.response
        ? action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data
        : "";
    });
    builder.addCase(projectCreation.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(projectCreation.fulfilled, (state, action) => {
      const { message } = action.payload || {};
      state.isLoading = false;
      state.message = message;
      state.isCreated = true;
    });
    builder.addCase(projectCreation.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload.response
        ? action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data
        : "";
    });

    builder.addCase(acceptInvitation.pending, (state, action) => {
      state.isInvitationLoading = true;
      state.projectId = action.meta.arg.id;
    });
    builder.addCase(acceptInvitation.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.isInvitationLoading = false;
        state.isInvitationAccepted = true;
        state.projectId = action.meta.arg.id;
      }
    });
    builder.addCase(acceptInvitation.rejected, (state, action) => {
      state.isInvitationLoading = false;
      state.errorMessage = action.payload.response
        ? action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data
        : "";
    });

    builder.addCase(declineInvitation.pending, (state, action) => {
      state.isInvitationLoading = true;
      state.projectId = action.meta.arg.id;
    });
    builder.addCase(declineInvitation.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.isInvitationLoading = false;
        state.isInvitationDenied = true;
        state.projectId = action.meta.arg.id;
      }
    });
    builder.addCase(declineInvitation.rejected, (state, action) => {
      state.isInvitationLoading = false;
      state.errorMessage = action.payload.response
        ? action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data
        : "";
    });
    builder.addCase(projectDelete.pending, (state, action) => {
      state.isDeleting = true;
      state.isDeleted = false;
      state.projectId = action.meta.arg.id;
    });
    builder.addCase(projectDelete.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.statusCode = "delete_initialised";
        state.statusMessage = "Deleting";
        state.isDeleted = true;
        state.isDeleting = false;
        state.projectId = action.meta.arg.id;
      }
    });
    builder.addCase(projectDelete.rejected, (state, action) => {
      state.isDeleting = false;
      state.isDeleted = false;
      state.projectId = action.meta.arg.id;
      state.projectDeleteErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(requisitionCount.pending, (state, action) => {
      state.isRequisitionLoading = true;
    })
    builder.addCase(requisitionCount.fulfilled, (state, action) => {
      const { requisitionCount } = action.payload || {};      
      state.reqCount = requisitionCount;
      state.isRequisitionLoading = false;
    })
    builder.addCase(requisitionCount.rejected, (state, action) => {
      state.isRequisitionLoading = false;
      state.requisitionErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    })

    builder.addCase(requisitionList.pending, (state, action) => {
      state.isRequisitionLoading = true;
    })
    builder.addCase(requisitionList.fulfilled, (state, action) => {
      const { requisitions } = action.payload || {};
      state.reqList = requisitions;
      state.isRequisitionLoading = false;
    })
    builder.addCase(requisitionList.rejected, (state, action) => {
      state.isRequisitionLoading = false;
      state.requisitionListErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    })

    builder.addCase(requisitionApprove.pending, (state, action) => {
      state.isRequisitionLoading = true;
    })
    builder.addCase(requisitionApprove.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.isApproved = true;
        state.isRequisitionLoading = false;
      }
    })
    builder.addCase(requisitionApprove.rejected, (state, action) => {
      state.isRequisitionLoading = false;
      state.requisitionErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    })

    builder.addCase(requisitionCancel.pending, (state, action) => {
      state.isRequisitionLoading = true;
    })
    builder.addCase(requisitionCancel.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.isCancelled = true;
        state.isRequisitionLoading = false;
      }
    })
    builder.addCase(requisitionCancel.rejected, (state, action) => {
      state.isRequisitionLoading = false;
      state.requisitionErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    })
  },
});

export const { resetProjectError } = projectSlice.actions;

export default projectSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const listFirewallRules = createAsyncThunk(
  API_END_POINTS.INSTANCE_FIREWALL,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const listFirewallUrl = API_END_POINTS.INSTANCE_FIREWALL;
      const url = listFirewallUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const firewallRuleCreation = createAsyncThunk(
  API_END_POINTS.INSTANCE_FIREWALL_CREATE,
  async (payload, thunkAPI) => {
    try {
      const { id, data } = payload;
      const createFirewallUrl = API_END_POINTS.INSTANCE_FIREWALL;
      const url = createFirewallUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.post(url, data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const firewallRuleDeletion = createAsyncThunk(
  API_END_POINTS.INSTANCE_FIREWALL_DELETE,
  async (payload, thunkAPI) => {
    try {
      const { id, pos } = payload;
      const deleteFirewallUrl = API_END_POINTS.INSTANCE_FIREWALL_DELETE;
      const url = deleteFirewallUrl
        .replace("{instance_id}", id.toString())
        .replace("{rule_id}", pos.toString());
      const res = await httpClient.delete(url);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  rulesList: [],
  isLoading: false,
  isPageLoading: false,
  errorMessage: null,
  isCreating: false,
  isSuccess: false,
  firewallRuleCreationErrors: null,
  isDeleteSuccess: false,
  isDeleteSuccessErrors: false,
};

export const fwRulesSlice = createSlice({
  name: "fwRules",
  initialState: initialState,
  // non-async reducers
  reducers: {
    resetFirewall: (state) => {
      state.rulesList = [];
      state.isLoading = false;
      state.errorMessage = null;
      state.isCreating = false;
      state.isSuccess = false;
      state.firewallRuleCreationErrors = null;
      state.isDeleteSuccess = false;
      state.isDeleteSuccessErrors = null;
      state.isPageLoading = false;
    },
    resetFirewallError: (state) => {
      state.firewallRuleCreationErrors = null;
      state.isDeleteSuccessErrors = null;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(listFirewallRules.pending, (state, action) => {
      state.isLoading = true;
      state.isPageLoading = true;
    });
    builder.addCase(listFirewallRules.fulfilled, (state, action) => {
      const { rules } = action.payload || {};
      state.isLoading = false;
      state.isPageLoading = false;
      state.rulesList = rules;
    });
    builder.addCase(listFirewallRules.rejected, (state, action) => {
      state.isLoading = false;
      state.isPageLoading = false;
      state.errorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
    builder.addCase(firewallRuleCreation.pending, (state, action) => {
      state.isCreating = true;
    });
    builder.addCase(firewallRuleCreation.fulfilled, (state, action) => {
      state.isSuccess = true;
      state.isCreating = false;
    });
    builder.addCase(firewallRuleCreation.rejected, (state, action) => {
      state.isCreating = false;
      state.firewallRuleCreationErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
    builder.addCase(firewallRuleDeletion.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(firewallRuleDeletion.fulfilled, (state, action) => {
      state.isDeleteSuccess = true;
      state.isLoading = false;
    });
    builder.addCase(firewallRuleDeletion.rejected, (state, action) => {
      state.isLoading = false;
      state.isDeleteSuccessErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
  },
});

export const { resetFirewall, resetFirewallError } = fwRulesSlice.actions;
export default fwRulesSlice.reducer;

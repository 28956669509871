import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const projectFloatingIp = createAsyncThunk(
  API_END_POINTS.FLOATING_IP,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const projectFloatingIpUrl = API_END_POINTS.FLOATING_IP;
      const url = projectFloatingIpUrl.replace("{project_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const floatingIpCreation = createAsyncThunk(
  API_END_POINTS.FLOATING_IP_CREATION,
  async (params, thunkAPI) => {
    try {
      const { id, payload } = params;
      const projectFloatingIpUrl = API_END_POINTS.FLOATING_IP;
      const url = projectFloatingIpUrl.replace("{project_id}", id.toString());
      const res = await httpClient.post(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const attachFloatIp = createAsyncThunk(
  API_END_POINTS.FLOATING_IP_ATTACH,
  async (params, thunkAPI) => {
    try {
      const { floatingIpId, payload } = params;
      const attachFloatIpUrl = API_END_POINTS.FLOATING_IP_ATTACH;
      const url = attachFloatIpUrl.replace(
        "{floatingip_id}",
        floatingIpId.toString()
      );
      const res = await httpClient.put(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const detachFloatIp = createAsyncThunk(
  API_END_POINTS.FLOATING_IP_DETACH,
  async (params, thunkAPI) => {
    try {
      const { floatingIpId, payload } = params;
      const detachFloatIpsUrl = API_END_POINTS.FLOATING_IP_DETACH;
      const url = detachFloatIpsUrl.replace(
        "{floatingip_id}",
        floatingIpId.toString()
      );
      const res = await httpClient.put(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteFloatIp = createAsyncThunk(
  API_END_POINTS.FLOATING_IP_DELETE,
  async (payload, thunkAPI) => {
    try {
      const { floatingIpId } = payload;
      const deleteFloatIpUrl = API_END_POINTS.FLOATING_IP_DELETE;
      const url = deleteFloatIpUrl.replace(
        "{floatingip_id}",
        floatingIpId.toString()
      );
      const res = await httpClient.delete(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const floatingIpOverview = createAsyncThunk(
  API_END_POINTS.FLOATING_IP_OVERVIEW,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const floatingIpOverviewUrl = API_END_POINTS.FLOATING_IP_OVERVIEW;
      const url = floatingIpOverviewUrl.replace(
        "{floatingip_id}",
        id.toString()
      );
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  projectFloatingIpList: [],
  floatingIpDetails: null,
  isPageLoading: false,
  isLoading: false,
  isCreating: false,
  isSuccess: false,
  floatingIpId: "",
  isAttaching: false,
  isDetaching: false,
  isDeleting: false,
  isAttachSuccess: false,
  isDetachSuccess: false,
  isDeleteSuccess: false,
  statusMessage: "",
  floatingIpCreationErrors: null,
  floatingIpListErrors: null,
  floatingIpDeleteErrors: null,
  floatingIpDetachErrors: null,
  floatingIpAttachErrors: null,
  floatingIpdOverviewErrorMessage: null,
};

export const floatingIpSlice = createSlice({
  name: "floatingIp",
  initialState: initialState,
  // non-async reducers
  reducers: {
    resetFloatingIp: (state) => {
      state.projectFloatingIpList = [];
      state.isLoading = false;
      state.isCreating = false;
      state.isSuccess = false;
    },
    updateFloatIpData: (state, param) => {
      const { payload } = param;
      const newState = state.projectFloatingIpList.map((obj) => {
        if (obj.id === parseInt(payload.floatingIp)) {
          return {
            ...obj,
            status: payload.status,
            instanceId: payload.instanceId
              ? payload.instanceId
              : obj.instanceId,
            name: payload.name ? payload.name : obj.name,
            instanceName: payload.instanceName
              ? payload.instanceName
              : obj.instanceName,
            ipAddress: payload.ipAddress ? payload.ipAddress : obj.ipAddress,
          };
        }
        return obj;
      });
      state.projectFloatingIpList = newState;
    },
    refreshFloatingIp: (state, param) => {
      const { payload } = param;
      state.projectFloatingIpList = state.projectFloatingIpList.filter(
        (item) => item.id !== payload.floatingIp
      );
    },
    resetFloatingIpErrors: (state) => {
      state.floatingIpDetachErrors = null;
      state.floatingIpDeleteErrors = null;
      state.floatingIpCreationErrors = null;
      state.floatingIpAttachErrors = null;
      state.floatingIpdOverviewErrorMessage = null;
      state.floatingIpListErrors = null;
    },

    resetFloatingIpActions: (state) => {
      state.statusMessage = "";
      state.isDeleteSuccess = false;
      state.isDetachSuccess = false;
      state.isAttachSuccess = false;
      state.floatingIpId = 0;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(projectFloatingIp.pending, (state, action) => {
      state.isPageLoading = true;
      state.projectFloatingIpList = [];
    });
    builder.addCase(projectFloatingIp.fulfilled, (state, action) => {
      const { floatngIps } = action.payload || {};
      state.projectFloatingIpList = floatngIps;
      state.isPageLoading = false;
    });
    builder.addCase(projectFloatingIp.rejected, (state, action) => {
      state.isPageLoading = false;
      state.floatingIpListErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(floatingIpCreation.pending, (state, action) => {
      state.isCreating = true;
    });
    builder.addCase(floatingIpCreation.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.isSuccess = true;
        state.isCreating = false;
      }
    });
    builder.addCase(floatingIpCreation.rejected, (state, action) => {
      state.isCreating = false;
      state.floatingIpCreationErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(attachFloatIp.pending, (state, action) => {
      state.isLoading = true;
      state.statusMessage = "Attaching";
      state.isAttaching = true;
      state.floatingIpId = action.meta.arg.floatingIpId;
    });
    builder.addCase(attachFloatIp.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.isAttachSuccess = true;
        state.isAttaching = false;
        state.isLoading = false;
        state.statusMessage = "Attached";
        state.floatingIpId = action.meta.arg.floatingIpId;
      }
    });
    builder.addCase(attachFloatIp.rejected, (state, action) => {
      state.isLoading = false;
      state.statusMessage = "Detached";
      state.isAttaching = false;
      state.floatingIpId = action.meta.arg.floatingIpId;
      state.floatingIpAttachErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(detachFloatIp.pending, (state, action) => {
      state.isDetachSuccess = false;
      state.isDetaching = true;
      state.isLoading = true;
      state.statusMessage = "Detaching";
      state.floatingIpId = action.meta.arg.floatingIpId;
    });
    builder.addCase(detachFloatIp.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.isDetachSuccess = true;
        state.isDetaching = false;
        state.isLoading = false;
        state.statusMessage = "Detached";
        state.floatingIpId = action.meta.arg.floatingIpId;
      }
    });
    builder.addCase(detachFloatIp.rejected, (state, action) => {
      state.isDetachSuccess = false;
      state.isDetaching = false;
      state.isLoading = false;
      state.statusMessage = "Attached";
      state.floatingIpId = action.meta.arg.floatingIpId;
      state.floatingIpDetachErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(deleteFloatIp.pending, (state, action) => {
      state.floatingIpId = action.meta.arg.floatingIpId;
      state.isDeleteSuccess = false;
      state.isDeleting = true;
      state.isLoading = true;
      state.statusMessage = "Deleting";
    });
    builder.addCase(deleteFloatIp.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.floatingIpId = action.meta.arg.floatingIpId;
        state.isDeleteSuccess = true;
        state.isDeleting = false;
        state.isLoading = false;
        state.statusMessage = "Deleted";
      }
    });
    builder.addCase(deleteFloatIp.rejected, (state, action) => {
      state.floatingIpId = action.meta.arg.floatingIpId;
      state.isDeleteSuccess = false;
      state.isDeleting = false;
      state.isLoading = false;
      state.statusMessage = "Detached";

      state.floatingIpDeleteErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(floatingIpOverview.pending, (state, action) => {
      state.isLoading = true;
      state.floatingIpId = action.meta.arg.id;
    });
    builder.addCase(floatingIpOverview.fulfilled, (state, action) => {
      state.floatingIpDetails = action.payload || {};
      state.floatingIpId = action.meta.arg.id;
      state.isLoading = false;
    });
    builder.addCase(floatingIpOverview.rejected, (state, action) => {
      state.isLoading = false;
      state.floatingIpId = action.meta.arg.id;
      state.floatingIpdOverviewErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
  },
});

export const {
  resetFloatingIp,
  refreshFloatingIp,
  updateFloatIpData,
  resetFloatingIpErrors,
  resetFloatingIpActions,
} = floatingIpSlice.actions;

export default floatingIpSlice.reducer;

import axios from "axios";
import {
  API_BASE_URL,
  SYSTEM_TOKEN,
  API_END_POINTS,
  ROUTES,
} from "./constants";
import { getData } from "./storage";
import { logout } from "slices/user";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const SetupInterceptors = (store, navigate) => {
  axiosInstance.interceptors.request.use(
    (request) => {
      if (request.method === "delete") {
        request.headers["X-Destroy"] = "true";
      }
      if (
        request.url.includes(API_END_POINTS.LOGIN) ||
        request.url.includes(API_END_POINTS.SIGNUP) ||
        request.url.includes(API_END_POINTS.MFA) ||
        request.url.includes(API_END_POINTS.FORGOTPASSWORD) ||
        request.url.includes(API_END_POINTS.NEWS_LETTER_SUBSCRIBE)
      ) {
        request.headers["Authorization"] = SYSTEM_TOKEN;
      } else {
        const userToken = getData("userToken");
        if (userToken) {
          request.headers["Authorization"] = userToken;
        } else {
          const planUrlSplit = API_END_POINTS.PLANS.split("/");
          if (
            request.url.includes(API_END_POINTS.REGIONS) ||
            request.url.includes(planUrlSplit[0] + "/" + planUrlSplit[1])
          ) {
            request.headers["Authorization"] = SYSTEM_TOKEN;
          }
        }
      }

      return request;
    },
    (config) => {
      return config;
    },
    (error) => Promise.reject(error)
  );
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          store.dispatch(logout({ mode: "automatic" }));
          navigate("/" + ROUTES.LOGIN);
          return Promise.reject(err.response.data);
        }

        // if (err.response.status === 403 && err.response.data) {
        //   store.dispatch(logout())
        //   return Promise.reject(err.response.data);
        // }
      }
      return Promise.reject(err);
    }
  );
};

export default axiosInstance;

import * as React from "react";
import { useNavigate } from "react-router-dom";

const AppPermission = (props) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="min-tabcontent d-flex justify-content-center align-items-center empty-page">
      <div className=" h-100 d-flex justify-content-center align-items-center">
        <div className="text-center">
          <h1>404</h1>
          <h4>Page Not Found!</h4>
          <img src="/assets/img/no-results.svg" class="my-4" alt="" />
          <p className="Tab-subheading mb-3">
            We're sorry. The page you were looking for does not exist.
          </p>
          <button className="btn add-btn" onClick={goBack}>
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppPermission;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const instanceEventsList = createAsyncThunk(
  API_END_POINTS.EVENTS_INSTANCE,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const instanceEventsUrl = API_END_POINTS.EVENTS_INSTANCE;
      const url = instanceEventsUrl.replace("{instance_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userEventsList = createAsyncThunk(
  API_END_POINTS.EVENTS_USERS,
  async (payload, thunkAPI) => {
    try {
      const { year } = payload;
      const url = API_END_POINTS.EVENTS_USERS + "?year=" + year;
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const projectEventsList = createAsyncThunk(
  API_END_POINTS.EVENTS_PROJECT,
  async (payload, thunkAPI) => {
    try {
      const { id, year } = payload;
      const projectEventsListUrl =
        API_END_POINTS.EVENTS_PROJECT + "?year=" + year;
      const url = projectEventsListUrl.replace("{project_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  eventsLoading: false,
  isPageLoading: false,
  instanceEventsListLoading: false,
  userEventsListLoading: false,
  instanceEvents: [],
  userEvents: [],
  projectEvents: [],
  instanceEventsErrorMessage: null,
  projectEventsErrorMessage: null,
  userEventsErrorMessage: null,
};

export const eventsSlice = createSlice({
  name: "events",
  initialState: initialState,
  // non-async reducers
  reducers: {},
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(instanceEventsList.pending, (state, action) => {
      state.instanceEventsListLoading = true;
    });
    builder.addCase(instanceEventsList.fulfilled, (state, action) => {
      const { data } = action.payload || {};
      state.instanceEvents = data;
      state.instanceEventsListLoading = false;
    });
    builder.addCase(instanceEventsList.rejected, (state, action) => {
      state.instanceEventsListLoading = false;
      state.instanceEvents = []
      state.instanceEventsErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(userEventsList.pending, (state, action) => {
      state.userEventsListLoading = true;
    });
    builder.addCase(userEventsList.fulfilled, (state, action) => {
      const { data } = action.payload || {};
      state.userEvents = data;
      state.userEventsListLoading = false;
    });
    builder.addCase(userEventsList.rejected, (state, action) => {
      state.userEventsListLoading = false;
      state.userEventsErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(projectEventsList.pending, (state, action) => {
      state.eventsLoading = true;
      state.isPageLoading = true;
    });
    builder.addCase(projectEventsList.fulfilled, (state, action) => {
      const { data } = action.payload || {};
      state.projectEvents = data;
      state.eventsLoading = false;
      state.isPageLoading = false;
    });
    builder.addCase(projectEventsList.rejected, (state, action) => {
      state.eventsLoading = false;
      state.isPageLoading = false;
      state.projectEventsErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
  },
});

export default eventsSlice.reducer;

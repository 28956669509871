import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const imagesList = createAsyncThunk(
  API_END_POINTS.IMAGES,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const url = API_END_POINTS.IMAGES + id;
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  data: [],
  isLoading: false,
  errorMessage: null,
  isPageLoading: false,
};

export const imageSlice = createSlice({
  name: "images",
  initialState: initialState,
  // non-async reducers
  reducers: {},
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(imagesList.pending, (state, action) => {
      state.isPageLoading = true;
    });
    builder.addCase(imagesList.fulfilled, (state, action) => {
      const { images } = action.payload || {};
      state.isPageLoading = false;
      state.data = images;
    });
    builder.addCase(imagesList.rejected, (state, action) => {
      state.isPageLoading = false;
      state.errorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
  },
});

export default imageSlice.reducer;

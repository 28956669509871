import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const privateImagesList = createAsyncThunk(
  API_END_POINTS.PRIVATE_IMAGES,
  async (payload, thunkAPI) => {
    try {
      const { id, active } = payload;
      let imageUrl = API_END_POINTS.PRIVATE_IMAGES;
      if (active === 1) {
        imageUrl = API_END_POINTS.PRIVATE_IMAGES + "?active=1";
      }
      const url = imageUrl.replace("{project_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const privateImagesStatus = createAsyncThunk(
  API_END_POINTS.PRIVATE_IMAGE_STATUS,
  async (payload, thunkAPI) => {
    try {
      const { id, imageId } = payload;
      const imageUrl = API_END_POINTS.PRIVATE_IMAGE_STATUS;
      const url = imageUrl
        .replace("{project_id}", id.toString())
        .replace("{image_id}", imageId.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const privateImageCreation = createAsyncThunk(
  API_END_POINTS.PRIVATE_IMAGES_CREATE,
  async (payload, thunkAPI) => {
    try {
      const { id, data } = payload;
      const createImageUrl = API_END_POINTS.PRIVATE_IMAGES;
      const url = createImageUrl.replace("{project_id}", id.toString());
      const res = await httpClient.post(url, data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const privateImageDeletion = createAsyncThunk(
  API_END_POINTS.PRIVATE_IMAGES_DELETE,
  async (payload, thunkAPI) => {
    try {
      const { id, imageId } = payload;
      const deleteImageUrl = API_END_POINTS.PRIVATE_IMAGES_DELETE;
      const url = deleteImageUrl
        .replace("{project_id}", id.toString())
        .replace("{image_id}", imageId.toString());
      const res = await httpClient.delete(url);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  imageList: [],
  isLoading: false,
  loading: false,
  errorMessage: null,
  privateImagesErrorMessage: null,
  isSuccess: false,
  isCreating: false,
  imageCreationErrors: null,
  isDeleteSuccess: false,
  isDeleteSuccessErrors: null,
  imageId: "",
  isStatusSuccess: false,
  imageStatusResponse: null,
  isPageLoading: false,
  privateImageDeletionErrors: null,
};

export const privateImageSlice = createSlice({
  name: "privateImages",
  initialState: initialState,
  // non-async reducers
  reducers: {
    resetImage: (state) => {
      state.imageList = [];
    },
    resetPrivateImageStatus: (state) => {
      state.isLoading = false;
      state.loading = false;
      state.errorMessage = null;
      state.isCreating = false;
      state.isSuccess = false;
      state.isDeleting = false;
      state.isDeleteSuccess = false;
      state.imageId = 0;
      state.isStatusSuccess = false;
      state.imageStatusResponse = null;
      state.statusCode = "";
      state.statusMessage = "";
    },
    resetErrors: (state) => {
      state.privateImageDeletionErrors = null;
      state.imageCreationErrors = null;
      state.isDeleteSuccessErrors = null;
      state.privateImagesErrorMessage = null;
    },
    refreshPrivateImage: (state, param) => {
      const { payload } = param;
      state.imageList = state.imageList.filter(
        (item) => item.id !== payload.id
      );
    },
    updatePrivateImageStatus: (state, param) => {
      const { payload } = param;
      const newState = state.imageList.map((obj) => {
        if (obj.id === payload.id) {
          return {
            ...obj,
            statusCode: payload.statusCode,
            statusMessage: payload.statusMessage,
          };
        }
        return obj;
      });
      state.imageList = newState;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(privateImagesList.pending, (state, action) => {
      state.isPageLoading = true;
      state.imageList = [];
    });
    builder.addCase(privateImagesList.fulfilled, (state, action) => {
      const { images } = action.payload || {};
      state.isPageLoading = false;
      state.imageList = images;
    });
    builder.addCase(privateImagesList.rejected, (state, action) => {
      state.isPageLoading = false;
      state.privateImagesErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
    builder.addCase(privateImageCreation.pending, (state, action) => {
      state.isCreating = true;
    });
    builder.addCase(privateImageCreation.fulfilled, (state, action) => {
      state.isSuccess = true;
      state.isCreating = false;
    });
    builder.addCase(privateImageCreation.rejected, (state, action) => {
      state.isCreating = false;
      state.imageCreationErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
    builder.addCase(privateImageDeletion.pending, (state, action) => {
      state.isDeleteSuccess = false;
      state.isDeleting = true;
      state.imageId = action.meta.arg.imageId;
    });
    builder.addCase(privateImageDeletion.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.imageId = action.meta.arg.imageId;
        state.isDeleteSuccess = true;
        state.isDeleting = false;
      }
    });
    builder.addCase(privateImageDeletion.rejected, (state, action) => {
      state.isDeleteSuccess = false;
      state.isDeleting = false;
      state.imageId = action.meta.arg.imageId;
      state.privateImageDeletionErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
    builder.addCase(privateImagesStatus.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(privateImagesStatus.fulfilled, (state, action) => {
      state.imageStatusResponse = action.payload || {};
      state.imageId = action.meta.arg.imageId;
      state.isStatusSuccess = true;
      state.isLoading = false;
    });
    builder.addCase(privateImagesStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.instanceRenameErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
  },
});

export const {
  resetImage,
  resetErrors,
  refreshPrivateImage,
  resetPrivateImageStatus,
  updatePrivateImageStatus,
} = privateImageSlice.actions;
export default privateImageSlice.reducer;

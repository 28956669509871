import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "utils/constants";

const AppFooter = (props) => {
  return (
    <>
      <hr className="p-0 my-1 footer-line"></hr>
      <footer className="w-100 dashboard-footer">
        <div className="row p-3 mx-2">
          <div className="col-md-6 col-lg-6 col-sm-6 px-0 footer-copyright d-flex justify-content-start">
            &#169; Copyright VCloud.2022. All rights reserved.
          </div>
          <div className="col-md-6 col-lg-6 col-sm-6 px-0 footer-copyright d-flex justify-content-end">
            <ul className="mb-0">
              <li><Link to={"/" + ROUTES.TERMS_AND_CONDITIONS}>Terms And Conditions</Link> </li>
              <li><Link to={"/" + ROUTES.PRIVACY_POLICY}>Privacy Policy</Link> </li>
              <li><Link to={"/" + ROUTES.COOKIES_POLICY}>Cookies Policy</Link></li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

AppFooter.propTypes = {};

export default AppFooter;

import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/user";
import projectReducer from "./slices/project";
import limitReducer from "./slices/limits";
import regionReducer from "./slices/regions";
import imageReducer from "./slices/images";
import planReducer from "./slices/plans";
import instanceReducer from "./slices/instance";
import sshKeyReducer from "./slices/ssh-keys";
import volumeReducer from "./slices/volumes";
import backUpReducer from "./slices/backUp";
import statsReducer from "./slices/stats";
import bandwidthReducer from "./slices/bandwidth";
import fwRulesReducer from "./slices/firewall";
import privateImagesReducer from "./slices/privateimages";
import resourceReducer from "./slices/resource";
import billingReducer from "./slices/billing";
import eventsReducer from "./slices/events";
import floatingIpReducer from "./slices/floatingip";
import projectUserReducer from "./slices/projectusers";
import consoleReducer from "./slices/console";
import locationsReducer from "./slices/locations";
import paymentReducer from "./slices/payment";
import networkReducer from "./slices/networks";

const reducer = {
  user: userReducer,
  projects: projectReducer,
  limits: limitReducer,
  regions: regionReducer,
  images: imageReducer,
  plans: planReducer,
  instances: instanceReducer,
  sshKeys: sshKeyReducer,
  volumes: volumeReducer,
  backUp: backUpReducer,
  stats: statsReducer,
  bandwidth: bandwidthReducer,
  fwRules: fwRulesReducer,
  privateImages: privateImagesReducer,
  resource: resourceReducer,
  billing: billingReducer,
  events: eventsReducer,
  floatingIp: floatingIpReducer,
  projectUser: projectUserReducer,
  consoleUrl: consoleReducer,
  locations: locationsReducer,
  payment: paymentReducer,
  networks: networkReducer,
};

export const store = configureStore({
  reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";
import { getData, setData, removeData } from "utils/storage";

export const signup = createAsyncThunk(
  API_END_POINTS.SIGNUP,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.post(API_END_POINTS.SIGNUP, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const login = createAsyncThunk(
  API_END_POINTS.LOGIN,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.post(API_END_POINTS.LOGIN, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const validateMFA = createAsyncThunk(
  API_END_POINTS.MFA,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.post(API_END_POINTS.MFA, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  API_END_POINTS.LOGOUT,
  async (payload, thunkAPI) => {
    try {
      const { mode } = payload;
      if (mode === "manual") {
        await httpClient.get(API_END_POINTS.LOGOUT, payload);
      }
      return thunkAPI.dispatch(logoutSuccess());
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  API_END_POINTS.USER_DETAILS,
  async (payload, thunkAPI) => {
    try {
      const url = API_END_POINTS.USER_DETAILS;
      const res = await httpClient.get(url);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUserInvitations = createAsyncThunk(
  API_END_POINTS.USER_INVITATIONS,
  async (payload, thunkAPI) => {
    try {
      const url = API_END_POINTS.USER_INVITATIONS;
      const res = await httpClient.get(url);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  API_END_POINTS.CHANGE_PASSWORD,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.post(
        API_END_POINTS.CHANGE_PASSWORD,
        payload
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateProfile = createAsyncThunk(
  API_END_POINTS.EDIT_USER_DETAILS,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.put(API_END_POINTS.USER_DETAILS, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getBillingAddress = createAsyncThunk(
  API_END_POINTS.BILLING_ADDRESS_DETAILS,
  async (payload, thunkAPI) => {
    try {
      const url = API_END_POINTS.BILLING_ADDRESS_DETAILS;
      const res = await httpClient.get(url);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateBillingAddress = createAsyncThunk(
  API_END_POINTS.EDIT_BILLING_ADDRESS_DETAILS,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.put(
        API_END_POINTS.BILLING_ADDRESS_DETAILS,
        payload
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  API_END_POINTS.FORGOTPASSWORD,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.post(API_END_POINTS.FORGOTPASSWORD, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetPassword = createAsyncThunk(
  API_END_POINTS.RESETPASSWORD,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.post(API_END_POINTS.RESETPASSWORD, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const subscribeNewsLetter = createAsyncThunk(
  API_END_POINTS.NEWS_LETTER_SUBSCRIBE,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.post(
        API_END_POINTS.NEWS_LETTER_SUBSCRIBE,
        payload
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  firstName: getData("firstName") || "",
  lastName: getData("lastName") || "",
  email: getData("email") || "",
  userToken: getData("userToken") || "",
  baseCurrency: getData("baseCurrency") || "",
  createdAt: getData("createdAt") || "",
  forgotMFA: "",
  loginMFA: "",
  signupMFA: "",
  isMFASuccess: false,
  logoutSuccesses: false,
  isLoading: false,
  isGetUserDetailsLoading: false,
  isGetBillingAddressLoading: false,
  errorMessage: null,
  userDetails: null,
  billingAddressDetails: null,
  userInvitations: [],
  isPasswordChanged: false,
  isChangePasswordLoading: false,
  isprofileUpdateLoading: false,
  isResetPasswordLoading: false,
  isProfileUpdated: false,
  profileUpdateErrorMessage: null,
  isBlllingAddressUpdateLoading: false,
  isBlllingAddressUpdated: false,
  billingAddressUpdateErrorMessage: null,
  isSubscribeNewsLetterLoading: false,
  isSubscribeNewsLetter: false,
  subscribeNewsLetterErrorMessage: null,
};

export const userSlice = createSlice({
  name: "auth",
  initialState: initialState,
  // non-async reducers
  reducers: {
    logoutSuccess: (state) => {
      removeData("firstName");
      removeData("lasttName");
      removeData("email");
      removeData("userToken");
      removeData("baseCurrency");
      removeData("createdAt");
      Object.assign(state, initialState);
      state.userToken = "";
      state.logoutSuccesses = true;
      state.userDetails = null;
    },
    clearMfaKey: (state) => {
      state.signupMFA = "";
      state.loginMFA = "";
      state.forgotMFA = "";
    },
  },
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(signup.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(signup.fulfilled, (state, action) => {
      const { mfaKey } = action.payload;
      state.isLoading = false;
      state.signupMFA = mfaKey;
    });
    builder.addCase(signup.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true;
      state.logoutSuccesses = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      const { mfaKey } = action.payload;
      state.isLoading = false;
      state.loginMFA = mfaKey;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(forgotPassword.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      const { mfaKey } = action.payload;
      state.isLoading = false;
      state.forgotMFA = mfaKey;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
    builder.addCase(validateMFA.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(validateMFA.fulfilled, (state, action) => {
      const { firstName, lastName, email, userToken, baseCurrency, createdAt } =
        action.payload || {};
      state.isLoading = false;
      state.firstName = setData("firstName", firstName) || firstName;
      state.lastName = setData("lastName", lastName) || lastName;
      state.email = setData("email", email) || email;
      state.userToken = setData("userToken", userToken) || userToken;
      state.baseCurrency =
        setData("baseCurrency", baseCurrency) || baseCurrency;
      state.createdAt = setData("createdAt", createdAt) || createdAt;
      state.loginMFA = "";
      state.signupMFA = "";
      state.isMFASuccess = true;
    });
    builder.addCase(validateMFA.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(getUserDetails.pending, (state, action) => {
      state.isGetUserDetailsLoading = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      const { firstName, lastName, email } = action.payload || {};
      state.isGetUserDetailsLoading = false;
      state.userDetails = action.payload || {};
      state.firstName = setData("firstName", firstName) || firstName;
      state.lastName = setData("lastName", lastName) || lastName;
      state.email = setData("email", email) || email;
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.isGetUserDetailsLoading = false;
      state.errorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(getUserInvitations.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getUserInvitations.fulfilled, (state, action) => {
      const { invitationList } = action.payload || {};
      state.isLoading = false;
      state.userInvitations = invitationList;
    });
    builder.addCase(getUserInvitations.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(changePassword.pending, (state, action) => {
      state.isChangePasswordLoading = true;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      const { status } = action.payload;
      if (status === "success") {
        state.isChangePasswordLoading = false;
        state.isPasswordChanged = true;
      }
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.isChangePasswordLoading = false;
      state.passwordChangedErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(resetPassword.pending, (state, action) => {
      state.isResetPasswordLoading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      const { status } = action.payload;
      if (status === "success") {
        state.isResetPasswordLoading = false;
        state.isPasswordChanged = true;
      }
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.isResetPasswordLoading = false;
      state.passwordChangedErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(updateProfile.pending, (state, action) => {
      state.isprofileUpdateLoading = true;
      state.isProfileUpdated = false;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      const { status } = action.payload;
      if (status === "success") {
        state.isprofileUpdateLoading = false;
        state.isProfileUpdated = true;
      }
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.isprofileUpdateLoading = false;
      state.isProfileUpdated = false;
      state.profileUpdateErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(getBillingAddress.pending, (state, action) => {
      state.isGetBillingAddressLoading = true;
    });
    builder.addCase(getBillingAddress.fulfilled, (state, action) => {
      state.isGetBillingAddressLoading = false;
      state.billingAddressDetails = action.payload || {};
    });
    builder.addCase(getBillingAddress.rejected, (state, action) => {
      state.isGetBillingAddressLoading = false;
      state.errorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(updateBillingAddress.pending, (state, action) => {
      state.isBlllingAddressUpdateLoading = true;
    });
    builder.addCase(updateBillingAddress.fulfilled, (state, action) => {
      const { status } = action.payload;
      if (status === "success") {
        state.isBlllingAddressUpdateLoading = false;
        state.isBlllingAddressUpdated = true;
      }
    });
    builder.addCase(updateBillingAddress.rejected, (state, action) => {
      state.isBlllingAddressUpdateLoading = false;
      state.billingAddressUpdateErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(subscribeNewsLetter.pending, (state, action) => {
      state.isSubscribeNewsLetterLoading = true;
    });
    builder.addCase(subscribeNewsLetter.fulfilled, (state, action) => {
      const { status } = action.payload;
      if (status === "success") {
        state.isSubscribeNewsLetterLoading = false;
        state.isSubscribeNewsLetter = true;
      }
    });
    builder.addCase(subscribeNewsLetter.rejected, (state, action) => {
      state.isSubscribeNewsLetterLoading = false;
      state.subscribeNewsLetterErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
  },
});

export default userSlice.reducer;

export const { logoutSuccess, clearMfaKey } = userSlice.actions;

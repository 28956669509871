import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const instanceVolumes = createAsyncThunk(
  API_END_POINTS.INSTANCES_VOLUME,
  async (payload, thunkAPI) => {
    try {
      const { instanceId } = payload;
      const instanceVolumesUrl = API_END_POINTS.INSTANCES_VOLUME;
      const url = instanceVolumesUrl.replace(
        "{instance_id}",
        instanceId.toString()
      );
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const instanceVolumeCreation = createAsyncThunk(
  API_END_POINTS.VOLUMES,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.post(API_END_POINTS.VOLUMES, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const attachVolumes = createAsyncThunk(
  API_END_POINTS.VOLUMES_ATTACH,
  async (payload, thunkAPI) => {
    try {
      const { volumeId, instanceId } = payload;
      const attachVolumesUrl = API_END_POINTS.VOLUMES_ATTACH;
      const url = attachVolumesUrl
        .replace("{volume_id}", volumeId.toString())
        .replace("{instance_id}", instanceId.toString());
      const res = await httpClient.put(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const detachVolumes = createAsyncThunk(
  API_END_POINTS.VOLUMES_DETACH,
  async (payload, thunkAPI) => {
    try {
      const { volumeId } = payload;
      const detachVolumesUrl = API_END_POINTS.VOLUMES_DETACH;
      const url = detachVolumesUrl.replace("{volume_id}", volumeId.toString());
      const res = await httpClient.put(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteVolumes = createAsyncThunk(
  API_END_POINTS.VOLUMES_DELETE,
  async (payload, thunkAPI) => {
    try {
      const { volumeId } = payload;
      const deleteVolumesUrl = API_END_POINTS.VOLUMES_DELETE;
      const url = deleteVolumesUrl.replace("{volume_id}", volumeId.toString());
      const res = await httpClient.delete(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const volumeStatus = createAsyncThunk(
  API_END_POINTS.VOLUMES_STATUS,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const instanceListUrl = API_END_POINTS.VOLUMES_STATUS;
      const url = instanceListUrl.replace("{volume_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const projectVolumes = createAsyncThunk(
  API_END_POINTS.PROJECTS_VOLUME,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const projectVolumesUrl = API_END_POINTS.PROJECTS_VOLUME;
      const url = projectVolumesUrl.replace("{project_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const volumeOverview = createAsyncThunk(
  API_END_POINTS.VOLUME_OVERVIEW,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const volumeOverviewUrl = API_END_POINTS.VOLUME_OVERVIEW;
      const url = volumeOverviewUrl.replace("{volume_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  volumes: [],
  projectvolumesList: [],
  volumeOverview: null,
  projectvolumeListErrorMessage: null,
  isLoading: false,
  isCreating: false,
  isSuccess: false,
  isDeleteSuccess: false,
  isDetachSuccess: false,
  isAttachSuccess: false,
  volumeStatusResponse: null,
  volumeId: "",
  isStatusSuccess: false,
  instanceVolumesListErrors: null,
  instanceVolumeCreationErrors: null,
  volumeAttachErrors: null,
  volumeStatusErrorMessage: null,
  statusCode: "",
  statusMessage: "",
  isPageLoading: false,
  isInstanceVolumesLoading: false,
  deleteVolumesErrors: null,
  detachVolumesErrors: null,
  volumeOverviewErrorMessage: null,
};

export const volumeSlice = createSlice({
  name: "volume",
  initialState: initialState,
  // non-async reducers
  reducers: {
    resetVolume: (state) => {
      state.volumes = [];
      state.volumeDetails = null;
      state.volumeStatusResponse = null;
    },
    resetVolumeActions: (state) => {
      state.isSuccess = false;
      state.isDeleteSuccess = false;
      state.isDetachSuccess = false;
      state.isAttachSuccess = false;
      state.volumeStatusResponse = null;
      state.volumeId = 0;
      state.isStatusSuccess = false;
      state.statusCode = "";
      state.statusMessage = "";
      state.isInstanceVolumesLoading = false;
    },
    resetVolumeErrors: (state) => {
      state.volumeAttachErrors = null;
      state.deleteVolumesErrors = null;
      state.detachVolumesErrors = null;
      state.instanceVolumeCreationErrors = null;
      state.volumeOverviewErrorMessage = null;
      state.volumeStatusErrorMessage = null;
      state.projectvolumeListErrorMessage = null;
    },
    updateVolumeData: (state, param) => {
      const { payload } = param;
      const newState = state.projectvolumesList.map((obj) => {
        if (obj.id === parseInt(payload.volumeId)) {
          return {
            ...obj,
            name: payload.name ? payload.name : obj.name,
            instanceName: payload.instanceName
              ? payload.instanceName
              : obj.instanceName,
            size: payload.size ? payload.size : obj.size,
            createdDate: payload.createdDate
              ? payload.createdDate
              : obj.createdDate,
            statusCode: payload.statusCode,
            status: payload.statusCode,
            statusMessage: payload.statusMessage,
          };
        }
        return obj;
      });
      state.projectvolumesList = newState;
    },
    refreshProjectVolume: (state, param) => {
      const { payload } = param;
      state.projectvolumesList = state.projectvolumesList.filter(
        (item) => item.id !== payload.id
      );
    },

    updateInstanceVolumeData: (state, param) => {
      const { payload } = param;
      const newState = state.volumes.map((obj) => {
        if (obj.id === parseInt(payload.volumeId)) {
          return {
            ...obj,
            name: payload.name ? payload.name : obj.name,
            instanceName: payload.instanceName
              ? payload.instanceName
              : obj.instanceName,
            size: payload.size ? payload.size : obj.size,
            createdDate: payload.createdDate
              ? payload.createdDate
              : obj.createdDate,
            statusCode: payload.statusCode,
            status: payload.statusCode,
            statusMessage: payload.statusMessage,
          };
        }
        return obj;
      });
      state.volumes = newState;
    },
    refreshInstanceVolume: (state, param) => {
      const { payload } = param;
      state.volumes = state.volumes.filter((item) => item.id !== payload.id);
    },
  },
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(instanceVolumes.pending, (state, action) => {
      state.isInstanceVolumesLoading = true;
    });
    builder.addCase(instanceVolumes.fulfilled, (state, action) => {
      const { volumes } = action.payload || {};
      state.volumes = volumes;
      state.isInstanceVolumesLoading = false;
    });
    builder.addCase(instanceVolumes.rejected, (state, action) => {
      state.isInstanceVolumesLoading = false;
      state.instanceVolumesListErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(instanceVolumeCreation.pending, (state, action) => {
      state.isCreating = true;
    });
    builder.addCase(instanceVolumeCreation.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.isSuccess = true;
        state.isCreating = false;
        state.volumeId = action.meta.arg.id;
      }
    });
    builder.addCase(instanceVolumeCreation.rejected, (state, action) => {
      state.isCreating = false;
      state.instanceVolumeCreationErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(detachVolumes.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(detachVolumes.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.statusCode = "detach_initialised";
        state.statusMessage = "Detaching";
        state.isDetachSuccess = true;
        state.isLoading = false;
        state.volumeId = action.meta.arg.volumeId;
      }
    });
    builder.addCase(detachVolumes.rejected, (state, action) => {
      state.isLoading = false;
      state.detachVolumesErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(deleteVolumes.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteVolumes.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.statusCode = "delete_initialised";
        state.statusMessage = "Deleting";
        state.volumeId = action.meta.arg.volumeId;
        state.isDeleteSuccess = true;
        state.isLoading = false;
      }
    });
    builder.addCase(deleteVolumes.rejected, (state, action) => {
      state.isLoading = false;
      state.deleteVolumesErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(attachVolumes.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(attachVolumes.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.statusCode = "attach_initialised";
        state.statusMessage = "Attaching";
        state.isAttachSuccess = true;
        state.isLoading = false;
        state.volumeId = action.meta.arg.volumeId;
      }
    });
    builder.addCase(attachVolumes.rejected, (state, action) => {
      state.isLoading = false;
      state.volumeId = action.meta.arg.volumeId;
      state.volumeAttachErrors =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(volumeStatus.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(volumeStatus.fulfilled, (state, action) => {
      state.volumeStatusResponse = action.payload || {};
      state.volumeId = action.meta.arg.id;
      state.isStatusSuccess = true;
      state.isLoading = false;
    });
    builder.addCase(volumeStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.volumeId = action.meta.arg.id;
      state.volumeStatusErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(projectVolumes.pending, (state, action) => {
      state.isPageLoading = true;
    });
    builder.addCase(projectVolumes.fulfilled, (state, action) => {
      const { volumes } = action.payload || {};
      state.projectvolumesList = volumes;
      state.isPageLoading = false;
    });
    builder.addCase(projectVolumes.rejected, (state, action) => {
      state.isPageLoading = false;
      state.projectvolumeListErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(volumeOverview.pending, (state, action) => {
      state.isLoading = true;
      state.volumeId = action.meta.arg.id;
    });
    builder.addCase(volumeOverview.fulfilled, (state, action) => {
      state.volumeDetails = action.payload || {};
      state.volumeId = action.meta.arg.id;
      state.isLoading = false;
    });
    builder.addCase(volumeOverview.rejected, (state, action) => {
      state.isLoading = false;
      state.volumeId = action.meta.arg.id;
      state.volumeOverviewErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
  },
});

export const {
  resetVolume,
  resetVolumeActions,
  updateVolumeData,
  resetVolumeErrors,
  refreshProjectVolume,
  updateInstanceVolumeData,
  refreshInstanceVolume,
} = volumeSlice.actions;

export default volumeSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const sshKeyCreation = createAsyncThunk(
  API_END_POINTS.SSHKEY_CREATE,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.post(API_END_POINTS.SSHKEYS, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sshKeyList = createAsyncThunk(
  API_END_POINTS.SSHKEYS_PROJECT,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const sshKeyListUrl = API_END_POINTS.SSHKEYS_PROJECT;
      const url = sshKeyListUrl.replace("{project_id}", id.toString());

      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const keysList = createAsyncThunk(
  API_END_POINTS.SSHKEYS,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.get(API_END_POINTS.SSHKEYS, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteSshKey = createAsyncThunk(
  API_END_POINTS.SSHKEYS_DELETE,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const deleteSshKeysUrl = API_END_POINTS.SSHKEYS_DELETE;
      const url = deleteSshKeysUrl.replace("{ssh_key_id}", id.toString());
      const res = await httpClient.delete(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  data: [],
  list: [],
  listProjectKeys: [],
  isLoading: false,
  isKeysListLoading: false,
  isSshKeyListLoading: false,
  isDeleteSuccess: false,
  isDeleteLoading: false,
  errorMessage: null,
  deleteErrorMessage: null,
  isSuccess: false,
};

export const sshKeySlice = createSlice({
  name: "sshkeys",
  initialState: initialState,
  // non-async reducers
  reducers: {},
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(sshKeyList.pending, (state, action) => {
      state.isSshKeyListLoading = true;
    });
    builder.addCase(sshKeyList.fulfilled, (state, action) => {
      const { sshKeys } = action.payload || {};
      state.listProjectKeys = sshKeys;
      state.isSshKeyListLoading = false;
    });
    builder.addCase(sshKeyList.rejected, (state, action) => {
      state.isSshKeyListLoading = false;
      state.errorMessage = "";
    });

    builder.addCase(sshKeyCreation.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(sshKeyCreation.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.data = action.payload;
        state.isSuccess = true;
        state.isLoading = false;
      }
    });
    builder.addCase(sshKeyCreation.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });

    builder.addCase(keysList.pending, (state, action) => {
      state.isKeysListLoading = true;
    });
    builder.addCase(keysList.fulfilled, (state, action) => {
      const { sshKeys } = action.payload || {};
      state.list = sshKeys;
      state.isKeysListLoading = false;
    });
    builder.addCase(keysList.rejected, (state, action) => {
      state.isKeysListLoading = false;
      state.errorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
    builder.addCase(deleteSshKey.pending, (state, action) => {
      state.isDeleteLoading = true;
      state.isDeleteSuccess = false;
    });
    builder.addCase(deleteSshKey.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.isDeleteSuccess = true;
        state.isDeleteLoading = false;
      }
    });
    builder.addCase(deleteSshKey.rejected, (state, action) => {
      state.isDeleteLoading = false;
      state.isDeleteSuccess = false;
      state.deleteErrorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
  },
});

export default sshKeySlice.reducer;

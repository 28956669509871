import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const limitList = createAsyncThunk(
  API_END_POINTS.LIMITS,
  async (payload, thunkAPI) => {
    try {
      const res = await httpClient.get(API_END_POINTS.LIMITS, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  data: [],
  isLimitListLoading: false,
  errorMessage: null,
};

export const limitSlice = createSlice({
  name: "limits",
  initialState: initialState,
  // non-async reducers
  reducers: {},
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(limitList.pending, (state, action) => {
      state.isLimitListLoading = true;
    });
    builder.addCase(limitList.fulfilled, (state, action) => {
      const { resourceLimit } = action.payload || {};
      state.data = resourceLimit;
      state.isLimitListLoading = false;
    });
    builder.addCase(limitList.rejected, (state, action) => {
      state.isLimitListLoading = false;
      state.errorMessage =
        action.payload.response && action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data;
    });
  },
});

export default limitSlice.reducer;

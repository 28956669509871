import React from "react";
import { ROUTES } from "utils/constants";
import { NavLink } from "react-router-dom";

const AppHeader = (props) => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <div className="container-fluid pe-0">
          <NavLink
            to={"/" + ROUTES.PROJECT}
            className="navbar-brand d-flex align-content-center py-2 ms-2"
            role="tab"
            data-toggle="tab"
          >
            <img
              src="/assets/img/vodacom-logo-white.svg"
              alt="vodacom Logo white"
              height="26px"
              width="82px"
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-md-auto gap-2">
              <li className="nav-item rounded d-none d-lg-block">
                <div className="d-flex justify-content-between align-items-center balance py-2 px-3">
                  <img
                    src="/assets/img/current_balance_light.svg"
                    className="img-fluid"
                    width="10px"
                    height="10px"
                    alt=""
                  />
                  <span className="ms-1 label">Current Balance :</span>

                  <span className="ms-1 balance-amount">
                    {" "}
                    {props.baseCurrency}{" "}
                    {props.currentBalance.toLocaleString(undefined, {
                      maximumFractionDigits: 6,
                    })}
                  </span>
                </div>
              </li>
              <li className="nav-item rounded d-none d-lg-block">
                <div className="d-flex justify-content-between align-items-center profile py-2 px-3">
                  <img
                    className="img-fluid"
                    src="/assets/img/profile.svg"
                    width="20px"
                    height="20px"
                    alt="Profile"
                  />
                  <span className="ms-1 user_name"> {props.userName}</span>
                </div>
              </li>
              <li className="nav-item rounded d-lg-none d-block mt-2 mt-lg-0">
                <div className="dropdown-item px-2 py-1">
                  <div className="d-flex justify-content-between align-items-center submenu ">
                    <img
                      className="img-fluid"
                      src="/assets/img/profile.svg"
                      width="20px"
                      height="20px"
                      alt="Profile"
                    />
                    <span className="dropdown-item p-1 text-white">
                      {" "}
                      {props.userName}
                    </span>
                  </div>
                </div>
              </li>
              <li className="nav-item rounded d-lg-none d-block">
                <NavLink
                  to={ROUTES.ACCOUNT_SETTINGS}
                  className="dropdown-item px-2 py-1"
                  role="tab"
                  data-toggle="tab"
                >
                  <div className="d-flex justify-content-between align-items-center submenu ">
                    <img
                      className="img-fluid me-1"
                      src="/assets/img/settings.svg"
                      width="15px"
                      height="auto"
                      alt="Profile"
                    />
                    <span className="dropdown-item p-1 text-white">
                      {" "}
                      Account Settings
                    </span>
                  </div>
                </NavLink>
              </li>
              <li className="nav-item rounded d-lg-none d-block">
                <NavLink
                  to={ROUTES.ACCOUNT_USAGE}
                  className="dropdown-item px-2 py-1"
                  role="tab"
                  data-toggle="tab"
                >
                  <div className="d-flex justify-content-start align-items-center submenu ">
                    <img
                      className="img-fluid me-1"
                      src="/assets/img/billing.svg"
                      width="15px"
                      height="auto"
                      alt="Profile"
                    />
                    <span className="dropdown-item p-1 text-white">
                      {" "}
                      Usage Report
                    </span>
                  </div>
                </NavLink>
              </li>
              <li className="nav-item rounded d-lg-none d-block">
                <NavLink
                  to={ROUTES.ACCOUNT_SSHKEY}
                  className="dropdown-item px-2 py-1"
                  role="tab"
                  data-toggle="tab"
                >
                  <div className="d-flex justify-content-start align-items-center submenu ">
                    <img
                      className="img-fluid me-1"
                      src="/assets/img/settings.svg"
                      width="15px"
                      height="auto"
                      alt="Profile"
                    />
                    <span className="dropdown-item p-1 text-white">
                      {" "}
                      SSH Keys
                    </span>
                  </div>
                </NavLink>
              </li>
              <li className="nav-item rounded d-lg-none d-block mb-2 mb-lg-0">
                <button
                  className="dropdown-item px-2 py-1 "
                  onClick={() => props.userLogout()}
                >
                  <div className="d-flex justify-content-between align-items-center submenu ">
                    <img
                      className="img-fluid me-1"
                      src="/assets/img/logout.svg"
                      width="20px"
                      height="20px"
                      alt="Profile"
                    />
                    <span className="dropdown-item p-1 text-white">LOGOUT</span>
                  </div>
                </button>
              </li>
              <li className="nav-item dropdown rounded d-none d-lg-block">
                <a
                  className="nav-link nav-custom pe-3"
                  id="navbarDropdown"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="navbar-toggler-icon"></span>
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-end py-0"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <NavLink
                      to={ROUTES.ACCOUNT_SETTINGS}
                      className="dropdown-item px-2 py-1"
                      role="tab"
                      data-toggle="tab"
                    >
                      <div className="d-flex justify-content-start align-items-center submenu ">
                        <img
                          className="img-fluid me-1"
                          src="/assets/img/settings.svg"
                          width="15px"
                          height="auto"
                          alt="Profile"
                        />
                        <span className="p-1"> Account Settings</span>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={ROUTES.ACCOUNT_PAYMENT}
                      className="dropdown-item px-2 py-1"
                      role="tab"
                      data-toggle="tab"
                    >
                      <div className="d-flex justify-content-start align-items-center submenu ">
                        <img
                          className="img-fluid me-1"
                          src="/assets/img/current_balance.svg"
                          width="15px"
                          height="auto"
                          alt="Profile"
                        />
                        <span className="p-1"> Payment</span>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={ROUTES.ACCOUNT_USAGE}
                      className="dropdown-item px-2 py-1"
                      role="tab"
                      data-toggle="tab"
                    >
                      <div className="d-flex justify-content-start align-items-center submenu ">
                        <img
                          className="img-fluid me-1"
                          src="/assets/img/billing.svg"
                          width="15px"
                          height="auto"
                          alt="Profile"
                        />
                        <span className="p-1"> Usage Report</span>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={ROUTES.ACCOUNT_SSHKEY}
                      className="dropdown-item px-2 py-1"
                      role="tab"
                      data-toggle="tab"
                    >
                      <div className="d-flex justify-content-start align-items-center submenu ">
                        <img
                          className="img-fluid me-1"
                          src="/assets/img/settings.svg"
                          width="15px"
                          height="auto"
                          alt="Profile"
                        />
                        <span className="p-1"> SSH Keys</span>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider my-0" />
                  </li>
                  <li>
                    <button
                      className="dropdown-item px-2 py-1 "
                      onClick={() => props.userLogout()}
                    >
                      <div className="d-flex justify-content-between align-items-center submenu ">
                        <img
                          className="img-fluid me-1"
                          src="/assets/img/logout.svg"
                          width="20px"
                          height="20px"
                          alt="Profile"
                        />
                        <span className="dropdown-item p-1">LOGOUT</span>
                      </div>
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

AppHeader.propTypes = {};

export default AppHeader;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_END_POINTS } from "utils/constants";
import httpClient from "utils/http-client";

export const projecUserstList = createAsyncThunk(
  API_END_POINTS.PROJECT_USERS,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const projecUserstListsUrl = API_END_POINTS.PROJECT_USERS;
      const url = projecUserstListsUrl.replace("{project_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const invitedUserstList = createAsyncThunk(
  API_END_POINTS.PROJECT_INVITED_USERS,
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const invitedUserstListUrl = API_END_POINTS.PROJECT_INVITED_USERS;
      const url = invitedUserstListUrl.replace("{project_id}", id.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const projectUserPermissions = createAsyncThunk(
  API_END_POINTS.PROJECT_USER_PERMISSIONS,
  async (payload, thunkAPI) => {
    try {
      const { id, userId } = payload;
      const projectUserPermissionsUrl = API_END_POINTS.PROJECT_USER_PERMISSIONS;
      const url = projectUserPermissionsUrl
        .replace("{project_id}", id.toString())
        .replace("{user_id}", userId.toString());
      const res = await httpClient.get(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateUserPermissions = createAsyncThunk(
  API_END_POINTS.PROJECT_USER_PERMISSION_EDIT,
  async (params, thunkAPI) => {
    try {
      const { id, userId, payload } = params;
      const updateUserPermissionsUrl = API_END_POINTS.PROJECT_USER_PERMISSIONS;
      const url = updateUserPermissionsUrl
        .replace("{project_id}", id.toString())
        .replace("{user_id}", userId.toString());
      const res = await httpClient.put(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteProjectUser = createAsyncThunk(
  API_END_POINTS.PROJECT_USER_DELETE,
  async (params, thunkAPI) => {
    try {
      const { id, userId } = params;
      const deleteProjectUserUrl = API_END_POINTS.PROJECT_USER_DELETE;
      const url = deleteProjectUserUrl
        .replace("{project_id}", id.toString())
        .replace("{user_id}", userId.toString());
      const res = await httpClient.delete(url, params);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteInvitedUser = createAsyncThunk(
  API_END_POINTS.PROJECT_INVITE_DELETE,
  async (params, thunkAPI) => {
    try {
      const { id, inviteId } = params;
      const deleteProjectUserUrl = API_END_POINTS.PROJECT_INVITE_DELETE;
      const url = deleteProjectUserUrl
        .replace("{project_id}", id.toString())
        .replace("{invite_id}", inviteId.toString());
      const res = await httpClient.delete(url, params);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const inviteProjectUser = createAsyncThunk(
  API_END_POINTS.PROJECT_USER_INVITE,
  async (params, thunkAPI) => {
    try {
      const { id, payload } = params;
      const inviteProjectUserUrl = API_END_POINTS.PROJECT_USERS;
      const url = inviteProjectUserUrl.replace("{project_id}", id.toString());
      const res = await httpClient.post(url, payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  projectUsersLoading: false,
  projecUserstListLoading: false,
  invitedUserstListLoading: false,
  permissionUpdateLoading: false,
  projectInvitedDeleting: false,
  projectUsers: [],
  invitedUsers: [],
  userPermissions: [],
  userId: "",
  permissionUpdated: false,
  userDeleted: false,
  projectUsersDeleting: false,
  userInvited: false,
  inviteDeleted: false,
  projectUsersErrorMessage: null,
  permissionUpdateErrorMessage: null,
  projectUsersDeleteErrorMessage: null,
  projectUsersInviteErrorMessage: null,
  projectInvitedDeleteErrorMessage: null,
};

export const projectUserSlice = createSlice({
  name: "projectUser",
  initialState: initialState,
  // non-async reducers
  reducers: {
    resetPermission: (state, action) => {
      state.permissionUpdated = false;
      state.userDeleted = false;
      state.projectUsersDeleting = false;
      state.userInvited = false;
      state.inviteDeleted = false;
      state.permissionUpdateErrorMessage = null;
      state.projectUsersDeleteErrorMessage = null;
      state.projectUsersInviteErrorMessage = null;
    },
    updatePermissionData: (state, param) => {
      const { payload } = param;
      const newState = state.userPermissions.map((obj) => {
        if (obj.name === payload.name) {
          return {
            ...obj,
            readOnly: payload.readOnly,
            readWrite: payload.readWrite,
            noAccess: payload.noAccess,
          };
        }
        return obj;
      });
      state.userPermissions = newState;
    },
    resetPermissionError: (state) => {
      state.projectUsersErrorMessage = null;
      state.permissionUpdateErrorMessage = null;
      state.projectUsersDeleteErrorMessage = null;
      state.projectUsersInviteErrorMessage = null;
      state.projectInvitedDeleteErrorMessage = null;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(projecUserstList.pending, (state, action) => {
      state.projecUserstListLoading = true;
      state.projectUsers = [];
    });
    builder.addCase(projecUserstList.fulfilled, (state, action) => {
      const { userList } = action.payload || {};
      state.projecUserstListLoading = false;
      state.projectUsers = userList;
    });
    builder.addCase(projecUserstList.rejected, (state, action) => {
      state.projecUserstListLoading = false;
      state.projectUsersErrorMessage = action.payload.response
        ? action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data
        : "";
    });

    builder.addCase(invitedUserstList.pending, (state, action) => {
      state.invitedUserstListLoading = true;
      state.invitedUsers = [];
    });
    builder.addCase(invitedUserstList.fulfilled, (state, action) => {
      const { invitationList } = action.payload || {};
      state.invitedUserstListLoading = false;
      state.invitedUsers = invitationList;
    });
    builder.addCase(invitedUserstList.rejected, (state, action) => {
      state.invitedUserstListLoading = false;
      state.projectUsersErrorMessage = action.payload.response
        ? action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data
        : "";
    });

    builder.addCase(projectUserPermissions.pending, (state, action) => {
      state.projectUsersLoading = true;
    });
    builder.addCase(projectUserPermissions.fulfilled, (state, action) => {
      const { permission, userId } = action.payload || {};
      state.projectUsersLoading = false;
      state.userPermissions = permission;
      state.userId = userId;
    });
    builder.addCase(projectUserPermissions.rejected, (state, action) => {
      state.projectUsersLoading = false;
      state.projectUsersErrorMessage = action.payload.response
        ? action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data
        : "";
    });

    builder.addCase(updateUserPermissions.pending, (state, action) => {
      state.permissionUpdateLoading = true;
    });
    builder.addCase(updateUserPermissions.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.permissionUpdateLoading = false;
        state.permissionUpdated = true;
      }
    });
    builder.addCase(updateUserPermissions.rejected, (state, action) => {
      state.permissionUpdateLoading = false;
      state.permissionUpdateErrorMessage = action.payload.response
        ? action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data
        : "";
    });

    builder.addCase(deleteProjectUser.pending, (state, action) => {
      state.projectUsersDeleting = true;
    });
    builder.addCase(deleteProjectUser.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.projectUsersDeleting = false;
        state.userDeleted = true;
      }
    });
    builder.addCase(deleteProjectUser.rejected, (state, action) => {
      state.projectUsersDeleting = false;
      state.projectUsersDeleteErrorMessage = action.payload.response
        ? action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data
        : "";
    });

    builder.addCase(inviteProjectUser.pending, (state, action) => {
      state.projectUsersLoading = true;
    });
    builder.addCase(inviteProjectUser.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.projectUsersLoading = false;
        state.userInvited = true;
      }
    });
    builder.addCase(inviteProjectUser.rejected, (state, action) => {
      state.projectUsersLoading = false;
      state.projectUsersInviteErrorMessage = action.payload.response
        ? action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data
        : "";
    });

    builder.addCase(deleteInvitedUser.pending, (state, action) => {
      state.projectInvitedDeleting = true;
    });
    builder.addCase(deleteInvitedUser.fulfilled, (state, action) => {
      const { status } = action.payload || {};
      if (status === "success") {
        state.projectInvitedDeleting = false;
        state.inviteDeleted = true;
      }
    });
    builder.addCase(deleteInvitedUser.rejected, (state, action) => {
      state.projectInvitedDeleting = false;
      state.projectInvitedDeleteErrorMessage = action.payload.response
        ? action.payload.response.data.data
          ? action.payload.response.data.data
          : action.payload.response.data
        : "";
    });
  },
});

export const { resetPermission, updatePermissionData, resetPermissionError } =
  projectUserSlice.actions;

export default projectUserSlice.reducer;

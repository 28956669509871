import { element } from "prop-types";
import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { AppLayout } from "components/app-layout";
import { ROUTES } from "utils/constants";
import { AppFooter } from "components/app-footer";

const dashboardLinks = [
  {
    to: ROUTES.PROJECT,
    label: "PROJECTS",
  },
  {
    to: ROUTES.LIMITS,
    label: "LIMITS",
  },
  {
    to: ROUTES.ACTIVITIES,
    label: "ACTIVITIES",
  },
];

const AppDashboard = ({ children }) => {
  return (
    <>
      <AppLayout>
        <>
          <ul
            className="nav nav-tabs d-flex justify-content-center sub-tab"
            role="tablist"
          >
            {dashboardLinks.map(({ to, label }) => (
              <li key={to} className="nav-item">
                <NavLink
                  to={"/" + to}
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  role="tab"
                  data-toggle="tab"
                >
                  {label}
                </NavLink>
              </li>
            ))}
          </ul>
          <Outlet />
        </>
      </AppLayout>
      <AppFooter />
    </>
  );
};

AppDashboard.propTypes = {
  children: element,
};

export default AppDashboard;
